$primaryLightColor: #7fa7cc;
$primaryColor: #557daa;
$primaryDarkColor: #3e6192;
$primaryDarkerColor: #2a487a;
$primaryTextColor: #f8fafc;

$highlightBg: $primaryColor;
$highlightTextColor: $primaryTextColor;

@import '../../sass/theme/_theme_light';
