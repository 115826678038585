.float-left {
  float: left;
}

.float-right {
  float: right;
}

.display-root {
  display: flow-root;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

.color-gray {
  color: #6c757d !important;
}

.breadcrumb-color {
  color: rgba(94, 94, 94, 0);
}

.bg-color-fc {
  background: #fcfcfc !important;
}

.bg-app {
  background: $primary;
}

.p-normalize {
  padding: 0.75rem 1rem;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-rem-875 {
  font-size: 0.875rem;
}

.btn-transparent {
  background: transparent !important;
}

.m-0 {
  margin: 0;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.p-0 {
  padding: 0;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-7 {
  padding-left: 7px !important;
}

.pr-7 {
  padding-right: 7px !important;
}
