@charset "utf-8";

/**************************************************************
	layout popup
**************************************************************/

.ddp-wrap-popup2 {
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #fff;
  z-index: 11;
  text-align: left;
  -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

  /* drop shadow */
  -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

  /* drop shadow */
  box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

  /* drop shadow */

  .ddp-ui-label {
    padding: 5px 10px 0px 10px;
    color: #b7b9c2;
    font-size: 12px;
    font-weight: bold;

    &:first-of-type {
      margin-top: 7px;
    }
  }

  &.ddp-types2 {
    max-height: 220px;
    overflow-y: auto;

    ul li a {
      padding-right: 14px;
    }
  }

  span {
    &.ddp-txt-label {
      display: block;
      margin: 10px 0 0 0;
      padding: 6px 14px 7px 14px;
      color: #b7b9c2;
      font-size: 12px;
      font-weight: 700;
      cursor: default;
    }

    &.ddp-txt-label2 {
      display: block;
      padding: 10px 13px 4px 13px;
      color: #b7b9c2;
      font-size: 12px;
      font-weight: bold;
    }
  }

  &.ddp-multy {
    padding: 10px 0;

    .ddp-list-popup {
      padding: 0;
    }
  }

  &.ddp-types ul.ddp-list-popup {
    li {
      a {
        position: relative;
        padding-left: 35px;
        font-weight: normal;

        &:hover {
          background-color: #f6f6f7;
        }
      }

      ul.ddp-list-popup.ddp-padd li a {
        padding-left: 6px;
      }
    }

    white-space: nowrap;

    &.ddp-type li a {
      padding-left: 14px;
    }

    li {
      a span.ddp-data-name {
        color: #b7b9c2;
      }

      span.ddp-noresult {
        display: block;
        padding: 5px 10px;
        color: #b7b9c2;
        cursor: default;
        font-size: 13px;
      }

      a em {
        &[class*='ddp-icon-type-'] {
          display: inline-block;
        }

        &[class*='ddp-icon-measure-'],
        &[class*='ddp-icon-dimension-'] {
          position: absolute;
          top: 50%;
          left: 10px;
          margin-top: -6px;
        }
      }

      &.ddp-txt-list a {
        padding-left: 14px;
      }
    }
  }
}

/* layout popup 아이콘 */

.ddp-types ul.ddp-list-popup {
  white-space: nowrap;

  + ul.ddp-list-popup {
    border-top: 1px solid #e7e7ea;
  }

  li {
    a {
      position: relative;
      padding-left: 35px;
      font-weight: normal;
    }

    ul.ddp-list-popup.ddp-padd li a {
      padding-left: 6px;
    }

    a {
      &:hover {
        background-color: #f6f6f7;
      }

      span.ddp-data-name {
        color: #b7b9c2;
      }

      .ddp-label-checkbox {
        i.ddp-icon-checkbox,
        input[type='checkbox'] {
          top: 8px;
          left: 20px;
        }
      }
    }
  }

  &.ddp-type li a {
    padding-left: 14px;
  }
}

.ddp-box-layout4 span.ddp-noresult {
  display: block;
  padding: 5px 10px;
  color: #b7b9c2;
  font-size: 13px;
  cursor: default;
}

ul.ddp-list-popup {
  padding: 3px 0;

  li {
    position: relative;

    a {
      display: block;
      position: relative;
      padding: 6px 14px 6px 14px;
      font-size: 13px;
      color: #4b515b;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: #f6f6f7;
      }

      .ddp-icons {
        display: inline-block;
        position: relative;
        width: 17px;
        height: 15px;
        vertical-align: middle;

        [class*='ddp-icon-'] {
          position: absolute;
          top: 1px;
          left: 0;
        }
      }
    }

    &.ddp-selected > a {
      color: $primary !important;
      padding-right: 28px;
    }
  }

  &.ddp-type li.ddp-selected > a {
    padding-right: 14px;
  }

  &.ddp-hover-none li a:hover {
    background: none !important;
  }

  li {
    &.ddp-selected > a em {
      &.ddp-icon-check {
        display: block;
      }

      &.ddp-icon-type-ab {
        background-position-x: -70px;
      }

      &.ddp-icon-type-tf {
        background-position-x: -75px;
      }

      &.ddp-icon-type-int {
        background-position-x: -43px;
      }

      &.ddp-icon-type-float {
        background-position-x: -84px;
      }

      &.ddp-icon-type-calen,
      &.ddp-icon-type-latitude,
      &.ddp-icon-type-longitude {
        background-position-x: -60px;
      }

      &.ddp-icon-type-phone {
        background-position-x: -14px;
      }

      &.ddp-icon-type-email {
        background-position-x: -13px;
      }

      &.ddp-icon-type-gender {
        background-position-x: -12px;
      }

      &.ddp-icon-type-url {
        background-position-x: -16px;
      }

      &.ddp-icon-type-zipcode {
        background-position-x: -17px;
      }

      &.ddp-icon-type-private,
      &.ddp-icon-type-image {
        background-position-x: -12px;
      }

      &.ddp-icon-type-binary {
        background-position-x: -10px;
      }

      &.ddp-icon-type-spatial {
        background-position-x: -12px;
      }

      &.ddp-icon-type-etc {
        background-position-x: -15px;
      }

      &.ddp-icon-type-point {
        background-position-x: -10px;
      }

      &.ddp-icon-type-line {
        background-position-x: -11px;
      }

      &.ddp-icon-type-polygon {
        background-position-x: -14px;
      }

      &.ddp-icon-type-expression {
        background-position-x: -12px;
      }
    }

    a em.ddp-icon-time5 {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -7px;
    }

    &.ddp-selected > a em.ddp-icon-time5 {
      background-position: -14px -16px;
    }

    &.ddp-disabled {
      &:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: 10;
        cursor: no-drop;
      }

      &:hover .ddp-wrap-popup2 {
        display: none;
      }

      a {
        opacity: 0.3;
      }
    }

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      width: 160px;
      margin-left: -5px;
    }

    &:hover > .ddp-wrap-popup2 {
      display: block;
    }

    a {
      span.ddp-column-type {
        display: block;
        position: relative;
        padding-left: 20px;

        [class*='ddp-icon'] {
          left: 0;
        }
      }

      em.ddp-icon-view {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -4px;
        width: 4px;
        height: 7px;
        background: url(/assets/bi/images/icon_tagview.png) no-repeat;
        transform: rotate(180deg);
      }
    }

    &.ddp-sub a em.ddp-icon-view {
      background: url(/assets/bi/images/icon_dataview.png) no-repeat;
      background-position: left -19px;
      transform: rotate(0);
    }

    a em.ddp-icon-check {
      display: none;
      position: absolute;
      top: 50%;
      right: 10px;
      width: 11px;
      height: 8px;
      margin-top: -6px;
      background: url(/assets/bi/images/icon_select2.png) no-repeat;
      background-position: -15px top;
    }
  }
}

.ddp-types ul.ddp-list-popup li a em {
  &.ddp-icon-add2 {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -7px;
    width: 13px;
    height: 13px;
    background: url(/assets/bi/images/icon_add.png) no-repeat;
    background-position: -84px -22px;
  }

  &.ddp-icon-clone {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -7px;
    width: 13px;
    height: 13px;
    background: url(/assets/bi/images/icon_clone.png) no-repeat;
  }

  &.ddp-icon-snapshot {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -6px;
    width: 14px;
    height: 11px;
    background: url(/assets/bi/images/icon_shot.png) no-repeat;
    background-position: -34px top;
  }
}

/* sub popup */

ul.ddp-list-popup li.ddp-sub {
  .ddp-wrap-popup2 {
    display: none;
  }

  &:hover {
    background-color: #f6f6f7;

    .ddp-wrap-popup2 {
      display: block;
    }
  }
}

/**************************************************************
	selectbox - 1
**************************************************************/

.ddp-wrap-selectbox {
  position: relative;

  .ddp-data-selectbox {
    position: relative;
    padding-right: 16px;
    color: #90969f;
    font-size: 12px;
    cursor: pointer;

    &:after {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 8px;
      height: 4px;
      margin-top: -2px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      background-position: -8px top;
    }
  }

  .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    top: 20px;
    right: 0;
    white-space: nowrap;
  }

  &.ddp-selected .ddp-wrap-popup2 {
    display: block;
  }
}

/**************************************************************
	selectbox - 2
**************************************************************/

.ddp-type-selectbox {
  position: relative;
  padding: 6px 23px 6px 10px;
  border-radius: 2px;
  border: 1px solid #d0d1d9;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    border: 1px solid #8f96a0;
  }

  input.ddp-input-selectbox {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 60px;
    bottom: 0;
    width: 100%;
    padding: 7px 26px 6px 13px;
    box-sizing: border-box;
    border: none;
    font-size: 13px;
    background-color: #fff;
    z-index: 1;

    &.ddp-value {
      color: #666eb3;
    }
  }

  span.ddp-txt-selectbox {
    display: block;
    color: #4a515c;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;

    .ddp-icons {
      display: inline-block;
      float: left;
      position: relative;
      width: 17px;
      height: 16px;

      [class*='ddp-icon-'] {
        left: 0;
      }
    }

    span.ddp-type-txt {
      padding-left: 22px;
      color: #4b515b;
      font-size: 13px;
    }

    &.ddp-result {
      color: #4b515b;
    }

    em {
      &[class*='ddp-icon-type-'],
      &[class*='ddp-icon-dimension-'],
      &[class*='ddp-icon-measure-'] {
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -6px;
      }
    }
  }

  &:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 9px;
    width: 7px;
    height: 4px;
    margin-top: -2px;
    background: url('/assets/bi/images/icon_select.png') no-repeat;
    content: '';
  }

  &:hover:after {
    background-position: -8px 0;
    z-index: 1;
  }

  &.ddp-selected:after {
    background-position: -8px 0;
    z-index: 1;
    transform: rotate(180deg);
  }

  .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    top: 32px;
    left: 0;
    right: 0;
    max-height: 190px;
    overflow-y: auto;

    ul.ddp-list-selectbox2 li {
      padding: 5px 10px;

      label.ddp-label-checkbox span {
        font-size: 12px;
      }
    }
  }
}

ul.ddp-list-selectbox2.ddp-hover-none li:hover {
  background: none !important;
}

.ddp-type-selectbox {
  &.ddp-selected {
    border: 1px solid #8f96a0;

    .ddp-wrap-popup2,
    ul.ddp-list-selectbox,
    .ddp-input-selectbox {
      display: block;
    }

    &.ddp-del .ddp-input-selectbox {
      padding-right: 50px;

      + .ddp-btn-del {
        position: absolute;
        top: 50%;
        right: 25px;
        margin-top: -8px;
        width: 15px;
        height: 15px;
        background: url(/assets/bi/images/btn_close.png) no-repeat;
        background-position: left -132px;
        z-index: 1;
      }
    }

    .ddp-wrap-popup2.ddp-scroll-none {
      max-height: inherit;
      overflow: initial;
    }

    z-index: 55;
    border: 1px solid #8f96a0;
  }

  &.ddp-disabled {
    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(240, 240, 240, 0.3);
      cursor: no-drop;
      z-index: 1;
      content: '';
    }

    opacity: 0.6;

    &:hover {
      &:after {
        background-position-x: 0;
      }

      border: 1px solid #d0d1d9;
    }
  }

  ul.ddp-list-selectbox {
    &.ddp-selectdown {
      bottom: inherit;
      top: 34px;
      z-index: 50;

      &.ddp-ellipsis li a {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.ddp-pass {
        left: inherit;
        min-width: 100%;
        white-space: nowrap;
      }

      &.ddp-pass2 {
        left: 0;
        right: inherit;
        min-width: 100%;
        white-space: nowrap;
      }
    }

    li a span {
      &.ddp-txt-search {
        color: $primary;
      }

      &.ddp-num {
        margin-left: 5px;
        color: #90969f;
      }
    }

    &.ddp-scrollnone {
      overflow: inherit;
    }
  }

  &.ddp-setting {
    width: 290px;
  }

  &.ddp-full {
    width: 100% !important;
  }

  &.ddp-inline {
    display: inline-block;
    vertical-align: middle;
  }

  &.ddp-non-select {
    padding: 6px 10px 7px 10px;
    cursor: default;

    &:after {
      display: none;
    }
  }
}

.ddp-offset .ddp-drop {
  position: fixed !important;
  box-sizing: border-box;
  border-top: none;
}

.ddp-type-selectbox {
  &.ddp-nowrap-r ul.ddp-list-selectbox {
    left: inherit;
    right: 0;
    min-width: 100%;
    white-space: nowrap;
  }

  &.ddp-size {
    width: 78px;
    box-sizing: border-box;
    display: inline-block;
  }

  span.ddp-txt-selectbox {
    span.ddp-column-type {
      display: block;
      position: relative;
      padding-left: 20px;

      [class*='ddp-icon'] {
        left: 0;
      }
    }

    &.ddp-icon {
      padding-left: 25px;
    }
  }
}

/**************************************************************
	selectbox - 2 disabled
**************************************************************/

.ddp-selectbox-disabled {
  position: relative;

  &.ddp-inline {
    display: inline-block;
  }

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240, 240, 240, 0.3);
    cursor: no-drop;
    z-index: 1;
  }
}

/**************************************************************
	selectbox - 3
**************************************************************/

.ddp-type-selectbox {
  &.ddp-type-search-select {
    span.ddp-txt-selectbox {
      position: relative;
      padding-left: 20px;
      box-sizing: border-box;
      color: #b7bac2;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 11px;
        height: 11px;
        margin-top: -6px;
        background: url('/assets/bi/images/icon_search.png') no-repeat;
        background-position: -24px top;
        content: '';
      }
    }

    &.ddp-disabled {
      opacity: 0.5;
      cursor: no-drop;

      &:hover {
        border: 1px solid #d0d1d9;
      }
    }

    &.ddp-result span.ddp-txt-selectbox {
      color: #4b515b;
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }

  &.ddp-search-type .ddp-input-search {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/**************************************************************
	selectbox - 4
**************************************************************/

.ddp-type-dropdown {
  position: relative;

  a {
    &.ddp-data-dropdown {
      position: relative;
      padding-right: 15px;
      color: #90969f;

      &:hover {
        color: #545b65;
      }

      &:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 7px;
        height: 4px;
        margin-top: -2px;
        background: url(/assets/bi/images/icon_selecview.png) no-repeat;
        background-position: -21px -7px;
      }
    }

    &.ddp-data-sort {
      position: relative;
      color: #b7b9c2;

      &:hover {
        color: #4b515b;
      }

      em[class*='ddp-icon-array-'] {
        margin-left: 7px;
        top: 0;
      }
    }
  }

  .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    top: 100%;
    right: -20px;
    white-space: nowrap;
    min-width: 120px;
  }

  &.ddp-selected .ddp-wrap-popup2 {
    display: block;
  }
}

/**************************************************************
	hover selectbox
**************************************************************/

.ddp-hover-selectbox {
  display: inline-block;
  position: relative;
  min-width: 174px;
  margin: -8px 0 -8px -9px;
  border: 1px solid #fff;
  border-radius: 2px;

  &:hover,
  &.ddp-selected {
    border: 1px solid #acb0bb;
  }

  > a {
    display: block;
    position: relative;
    padding: 7px 23px 7px 9px;
    color: #4b515b;
    font-size: 13px;

    &.ddp-icon-type {
      padding-left: 26px;
    }
  }

  &:hover > a:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 9px;
    width: 7px;
    height: 4px;
    margin-top: -2px;
    background: url('/assets/bi/images/icon_select.png') no-repeat;
    content: '';
  }

  &.ddp-selected {
    > a:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 9px;
      width: 7px;
      height: 4px;
      margin-top: -2px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      content: '';
    }

    .ddp-wrap-popup2 {
      display: block;
    }
  }

  .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 3px;
    min-width: 100%;
  }
}

table.ddp-table-detail2 tr td .ddp-icon-type-txt {
  display: inline-block;
  position: relative;
  padding: 7px 9px 7px 26px;
  margin-top: -5px;
  margin-left: -8px;
}

/**************************************************************
	drop search
**************************************************************/

.ddp-wrap-drop-search {
  position: relative;

  .ddp-type-selectbox2 {
    position: relative;
    padding: 6px 26px 6px 13px;
    border-radius: 2px;
    border: 1px solid #d0d1d9;
    background-color: #fff;
    cursor: pointer;
  }
}

.ddp-type-selectbox2:hover {
  border: 1px solid #8f96a0;
}

.ddp-wrap-drop-search {
  .ddp-type-selectbox2 {
    &.ddp-selected {
      z-index: 30;
    }

    &:after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 12px;
      width: 7px;
      height: 4px;
      margin-top: -2px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      content: '';
    }

    &:hover:after {
      background-position: -8px top;
    }

    span.ddp-txt-selectbox {
      display: block;
      position: relative;
      padding-left: 20px;
      color: #b7bac2;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-wrap: normal;
      box-sizing: border-box;
    }
  }

  .ddp-ui-drop-search {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #fff;
    z-index: 10;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */

    &:after {
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 7px;
      height: 4px;
      margin-top: -2px;
      background: url('/assets/bi/images/icon_select.png') no-repeat;
      background-position: -8px top;
      content: '';
    }

    input.ddp-input-search {
      display: block;
      width: 100%;
      padding: 7px 26px 6px 13px;
      box-sizing: border-box;
      border: none;
      font-size: 13px;

      &.ddp-value {
        color: #666eb3;
      }
    }
  }

  .ddp-type-selectbox2 span.ddp-txt-selectbox:before {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 11px;
    height: 11px;
    margin-top: -6px;
    background: url('/assets/bi/images/icon_search.png') no-repeat;
    background-position: -24px top;
    content: '';
  }

  .ddp-ui-drop-search {
    ul.ddp-list-selectbox2 {
      max-height: 175px;
      padding: 3px 0;
      border-top: 1px solid #ddd;
      box-sizing: border-box;
      overflow-y: auto;
      white-space: nowrap;

      &.type-border {
        border-top: none;
      }

      &.type-scroll-none {
        max-height: none;
      }

      li {
        padding: 5px 13px;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          background-color: #f6f6f7;
        }

        a {
          display: block;
          margin: -5px -13px;
          padding: 5px 13px;
        }
      }

      &.ddp-list-popup li {
        a {
          padding-right: 28px;
        }

        &.ddp-selected a {
          color: #4b515b;
        }
      }

      li label.ddp-label-checkbox {
        padding: 5px 10px 5px 20px;
        margin: -5px -10px -5px 0;
      }
    }

    .ddp-data-message {
      padding: 5px 13px;
      color: #d0d1d8;
      font-size: 12px;
      cursor: default;
    }
  }

  &.ddp-selected .ddp-ui-drop-search {
    display: block;
    min-width: 160px;

    .ddp-data-title {
      padding: 15px 14px 4px 14px;
      font-size: 12px;
      color: #b5b9c2;
      font-weight: bold;
      border-bottom: none;
    }

    .ddp-noresult {
      display: block;
      padding: 5px 13px;
      color: #b7b9c2;
      font-size: 13px;
    }
  }

  &.ddp-disabled:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(240, 240, 240, 0.5);
    z-index: 1;
    cursor: no-drop;
    content: '';
  }

  &.ddp-result .ddp-type-selectbox2 span.ddp-txt-selectbox {
    padding-left: 0;
    color: #4b515b;

    &:before {
      display: none;
    }
  }
}

/**************************************************************
	drop down list
**************************************************************/

/*.ddp-wrap-drop-search.ddp-offset input.ddp-input-search {display:none;}*/
/*.ddp-wrap-drop-search.ddp-offset.ddp-selected input.ddp-input-search {display:block; position:absolute; top:0; left:0; right:0; width:100%; padding:7px 26px 6px 13px; box-sizing:border-box; border:1px solid #d0d1d9;}*/
/**************************************************************
	select list
**************************************************************/

.ddp-type-selectbox ul {
  &.ddp-list-selectbox {
    display: none;
    position: absolute;
    bottom: 34px;
    left: 0;
    right: 0;
    max-height: 160px;
    padding: 7px 0;
    background-color: #fff;
    border: 1px solid #ddd;
    overflow-y: auto;
    z-index: 1;
    box-sizing: border-box;
    -moz-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    -webkit-box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */
    box-shadow: 0 1px 3px rgba(84, 97, 104, 0.3);

    /* drop shadow */

    li {
      a {
        display: block;
        position: relative;
        padding: 5px 10px;
        color: #4b525b;
        font-size: 13px;

        &:hover {
          background-color: #f6f6f7;
        }
      }

      span.ddp-noresult {
        display: block;
        padding: 5px 10px;
        color: #b7b9c2;
        cursor: default;
        font-size: 13px;
      }

      .ddp-wrap-popup2 {
        position: absolute;
        left: 100%;
        bottom: 28px;
      }

      &:hover .ddp-wrap-popup2 {
        display: block;
      }

      a em.ddp-icon-subview {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -5px;
        width: 5px;
        height: 9px;
        background: url(/assets/bi/images/icon_subview.png) no-repeat;
      }
    }
  }

  &.ddp-list-command li {
    &.ddp-data-select {
      padding: 6px 10px;
      color: #adb0bb;
      font-size: 11px;

      strong {
        color: #656eb2;
      }
    }

    a {
      em.ddp-ui-round {
        display: inline-block;
        padding: 2px 2px 1px 2px;
        margin-left: 2px;
        margin-right: 5px;
        border-radius: 50%;
        border: 1px solid #656eb2;
        color: #656eb2;
        font-size: 9px;
      }

      span {
        &.ddp-ui-type {
          display: block;
          color: #656eb2;
          font-size: 12px;
        }

        &.ddp-ui-code {
          display: block;
          padding: 0 0 0 29px;
          color: #b7b9c2;
          font-size: 12px;
        }
      }
    }
  }
}

/* list command */

.ddp-box-command .ddp-type-selectbox ul.ddp-list-selectbox.ddp-list-command {
  max-height: 344px;

  li a:hover {
    background: none;
  }
}

/**************************************************************
	dropdown
**************************************************************/

.ddp-hover-status {
  display: inline-block;
  position: relative;

  .ddp-status-active {
    display: inline-block;
    position: relative;
    padding-right: 17px;
    cursor: pointer;

    .ddp-icon-view {
      display: inline-block;
      position: absolute;
      top: -3px;
      right: 0;
      width: 17px;
      height: 17px;

      &:before {
        display: inline-block;
        width: 7px;
        height: 4px;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -2px 0 0 -4px;
        background: url('/assets/bi/images/icon_select.png') no-repeat;
        background-position: -8px top;
      }
    }
  }

  .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    top: 20px;
    right: 0;
    min-width: 130px;
  }

  &.ddp-selected .ddp-wrap-popup2 {
    display: block;
  }
}
