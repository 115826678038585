.p-rating {
  .p-rating-icon {
    color: $ratingStarIconOffColor;
    margin-left: $inlineSpacing;
    transition: $formElementTransition;
    font-size: $ratingIconFontSize;

    &.p-rating-cancel {
      color: $ratingCancelIconColor;
    }

    &:focus {
      @include focused();
    }

    &:first-child {
      margin-left: 0;
    }

    &.pi-star-fill {
      color: $ratingStarIconOnColor;
    }
  }

  &:not(.p-disabled):not(.p-readonly) {
    .p-rating-icon {
      &:hover {
        color: $ratingStarIconHoverColor;
      }

      &.p-rating-cancel {
        &:hover {
          color: $ratingCancelIconHoverColor;
        }
      }
    }
  }
}

@if ($highlightBg == $ratingStarIconOnColor) {
  .p-highlight {
    .p-rating {
      .p-rating-icon {
        color: $highlightTextColor;
      }
    }
  }
}
