.layout-menu-dim {
  $menuBgColor: #3c4962;
  $menuShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);

  $rootMenuTextColor: #cdd2db;

  $rootMenuItemTextColor: #e5e8ec;
  $rootMenuItemHoverBgColor: #495771;
  $activeRootMenuItemTextColor: #ffffff;
  $menuItemIconColor: #e5e8ec;

  $submenuItemTextColor: #e5e8ec;
  $submenuItemHoverBgColor: #59667d;
  $activeSubmenuItemTextColor: #ffffff;

  $externalSubmenuBgColor: lighten(#3c4962, 3%);

  @import '../_menu_theme';
}
