@charset "utf-8";

/**************************************************************
  tags add
*************************************************************/

.ddp-wrap-edit3 .ddp-ui-edit-option .ddp-list-tags {
  margin-top: 0;
}

.ddp-list-tags {
  margin-top: -5px;

  .ddp-tags {
    display: inline-block;
    position: relative;
    padding: 5px 15px;
    background-color: #f2f1f7;
    border-radius: 4px;
    color: $primary;
    font-size: 13px;
    cursor: pointer;
    margin: 3px 6px 3px 0;

    a.ddp-btn-delete {
      display: none;
      position: absolute;
      top: -4px;
      right: -4px;
      content: '';
      width: 14px;
      height: 14px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: 0 -131px;
    }

    &:hover a.ddp-btn-delete {
      display: block;
    }

    &.ddp-txt {
      cursor: default;

      &:hover {
        padding: 5px 15px;

        a.ddp-btn-delete {
          display: none;
        }
      }
    }
  }

  .ddp-tag-default {
    display: inline-block;
    position: relative;
    padding: 5px 5px 5px 20px;
    margin: 3px 6px 3px 0;
    border-radius: 4px;
    color: $primary;
    font-size: 13px;
    box-sizing: border-box;

    &:before {
      display: inline-block;
      position: absolute;
      top: 5px;
      left: 9px;
      content: '#';
      color: $primary;
      font-size: 13px;
    }

    input {
      display: block;
      width: 100%;
      border: none;
      background: none;
      color: $primary;
      font-size: 13px;
    }
  }
}

/**************************************************************
    box-tag
**************************************************************/

.ddp-box-tag {
  display: inline-block;
  float: left;
  padding: 1px 3px 1px 3px;
  margin-right: 8px;
  border-radius: 1px;
  font-size: 10px;
  font-weight: normal;
  vertical-align: top;
  box-sizing: border-box;

  &.ddp-workbook {
    border: 1px solid $accent;
    color: $accent;
  }

  &.ddp-notebook {
    border: 1px solid #5ecfea;
    color: #5ecfea;
  }

  &.ddp-workbench {
    border: 1px solid #bac1e5;
    color: #8c97d3;
  }

  &.ddp-wargled {
    min-width: 54px;
    border: 1px solid #ee5398;
    color: #ee5398 !important;
    text-align: center;
  }

  &.ddp-imported {
    min-width: 54px;
    border: 1px solid #50549a;
    color: #50549a !important;
    text-align: center;
  }

  &.ddp-personal {
    width: 52px;
    border: 1px solid #cd2287;
    color: #cd2287 !important;
    text-align: center;
  }

  &.ddp-shared {
    width: 52px;
    border: 1px solid #3f72c1;
    color: #3f72c1 !important;
    text-align: center;
  }

  &.ddp-wargled {
    display: inline-block;
    min-width: 63px;
    padding: 0 3px;
    text-align: center;
    font-size: 10px;
    box-sizing: Border-box;
  }
}

.ddp-box-tag-value {
  &.ddp-database,
  &.ddp-datasource,
  &.ddp-hive,
  &.ddp-stagingdb,
  &.ddp-dataset {
    display: inline-block;
    min-width: 63px;
    padding: 0 3px;
    text-align: center;
    font-size: 10px;
    box-sizing: Border-box;
  }

  &.ddp-datasource {
    border: 1px solid #313869;
    color: #313869;
  }

  &.ddp-hive {
    border: 1px solid #f6a300;
    color: #f6a300;
  }

  &.ddp-stagingdb {
    border: 1px solid #8476df;
    color: #8476df;
  }

  &.ddp-database {
    border: 1px solid #5b8fe4;
    color: #5b8fe4;
  }

  &.ddp-dataset {
    border: 1px solid #46a8be;
    color: #46a8be;
  }
}

.ddp-box-power {
  display: inline-block;
  padding: 2px 4px 1px 4px;
  width: 60px;
  border-radius: 2px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;

  &.ddp-watcher,
  &.ddp-editor {
    background-color: #b7b9c2;
  }

  &.ddp-owner,
  &.ddp-manager {
    background-color: #4b515b;
  }
}

/**************************************************************
	new
**************************************************************/

.ddp-icon-new {
  color: $primary;
  font-size: 10px !important;

  &:before {
    display: inline-block;
    content: '';
    width: 5px;
    height: 5px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: $primary;
  }
}

/**************************************************************
	status
**************************************************************/

.ddp-status-pending {
  color: #ffb700;
  font-size: 11px;
  font-weight: bold;
}

.ddp-status-rejected {
  color: #f4434b;
  font-size: 11px;
  font-weight: bold;
}

.ddp-status-approved {
  color: #90969f;
  font-size: 11px;
  font-weight: bold;
}

.ddp-status-active {
  color: #4b525b;
  font-size: 11px;
  font-weight: bold;

  &.ddp-inactive {
    color: #90969f;
    font-size: 11px;
    font-weight: bold;
  }
}

.ddp-data-state {
  &.ddp-waiting {
    font-weight: bold;
    font-size: 12px;
    color: #90969f;
  }

  &.ddp-rejected {
    font-weight: bold;
    font-size: 12px;
    color: #eb5f58;
  }

  &.ddp-approved {
    font-weight: bold;
    font-size: 12px;
    color: #4c92e0;
  }

  &.ddp-inprogress {
    position: relative;
    font-weight: bold;
    font-size: 12px;
    color: #90969f;
  }

  &.ddp-fail {
    font-size: 11px;
    color: #eb5f58;
    font-weight: bold;
  }

  &.ddp-success {
    font-size: 11px;
    color: #4c92e0;
    font-weight: bold;
  }

  &.ddp-preparing {
    font-size: 11px;
    color: #4b515b;
    font-weight: bold;
  }

  &.ddp-running {
    font-size: 11px;
    color: #ffba00;
    font-weight: bold;
  }

  &.ddp-cancelled {
    font-size: 11px;
    color: #90969f;
    font-weight: bold;
  }

  &.ddp-scheduling {
    font-size: 11px;
    color: #10bf83;
    font-weight: bold;
  }

  &.ddp-creating {
    font-size: 11px;
    color: #ffba00;
    font-weight: bold;
  }

  &.ddp-unscheduled {
    font-size: 11px;
    color: #b7b9c2;
    font-weight: bold;
  }

  &.ddp-warning {
    font-size: 11px;
    color: #edb817;
    font-weight: bold;
  }

  &.ddp-ok {
    font-size: 11px;
    color: #5188d7;
    font-weight: bold;
  }

  &.ddp-inprogress {
    .ddp-icon-error2 {
      position: relative;
      top: 0;
      margin-left: 2px;
      background-position-x: -28px;
    }

    .ddp-ui-tooltip-info {
      display: none;
      position: absolute;
      top: -5px;
      left: 100%;
      margin-left: 8px;
    }

    &:hover .ddp-ui-tooltip-info {
      display: block;
      font-weight: normal;
    }
  }
}

.ddp-data-status {
  &.ddp-fail {
    font-size: 11px;
    color: #dc494f;
    font-weight: bold;
  }

  &.ddp-enabled {
    font-size: 11px;
    color: #10bf83;
    font-weight: bold;
  }

  &.ddp-preparing {
    font-size: 11px;
    color: #ffb700;
    font-weight: bold;
  }

  &.ddp-disabled {
    font-size: 11px;
    color: #b7b9c2;
    font-weight: bold;
  }
}

.ddp-tag-status {
  display: inline-block;
  width: 36px;
  padding: 3px 0 2px 0;
  border-radius: 3px;
  background-color: rgba(120, 196, 164, 0.1);
  color: #58bb90;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;

  &.type-error {
    color: #fff;
    background-color: #dc494f;
  }
}

.ddp-data-status {
  &.ddp-running {
    font-size: 12px;
    color: #10bf83;
    font-weight: bold;
  }

  &.ddp-pending {
    font-size: 12px;
    color: #8c6dd2;
    font-weight: bold;
  }

  &.ddp-waiting {
    font-size: 12px;
    color: #ffba00;
    font-weight: bold;
  }

  &.ddp-fail {
    font-size: 12px;
    color: #dc494f;
    font-weight: bold;
  }

  &.ddp-success {
    font-size: 12px;
    color: #3f72c1;
    font-weight: bold;
  }
}
