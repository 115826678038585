@charset "utf-8";

/**************************************************************
	LOADING
**************************************************************/

.ddp-loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(0, 0, 0, 0);
  z-index: 200;
}

.ddp-loading-part {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;

  .ddp-ui-loading {
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: -63px 0 0 0;
    text-align: center;

    .lds-wedges {
      position: relative;
      margin: 0 0 0 100px;
      top: 40px;
      left: 0;
      display: inline-block;
    }

    .ddp-btn-cancel a {
      font-size: 14px;
      text-align: center;
      text-decoration: underline;
    }
  }
}

@keyframes lds-wedges {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lds-wedges {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.lds-wedges {
  /* position:absolute; top:50%; left:50%; margin:-0px 0 0 -0px; background-image: url('/assets/bi/images/loading.gif'); background-repeat: no-repeat; */
  position: absolute;
  top: 50%;
  margin: -0px 0 0 -0px;

  .ant-spin .ant-spin-lg .ant-spin-spinning {
    /* --scroll-bar: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
    font-weight: 400;
    font-family: 'DM Sans', sans-serif !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    color: #1890ff;
    text-align: center;
    vertical-align: middle;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
      -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    position: static;
    display: inline-block;
    opacity: 1;
  }
}

.loading-wrapper-body {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.loading-wrapper {
  padding: 10px;
  border: 1px solid $primary;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  color: white;
  background: $primary;
}

.loading-wrapper-content {
  padding-right: 15px;
}

.ant-spin {
  float: left;
}

.lds-wedges {
  .ant-spin-dot.ant-spin-dot-spin {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }

  .ant-spin-dot-item {
    &:nth-child(1) {
      /*font-weight: 400;*/
      /*font-family: 'DM Sans', sans-serif !important;*/
      /*font-variant: tabular-nums;*/
      /*line-height: 1.5715;*/
      /*list-style: none;*/
      /*font-feature-settings: 'tnum', "tnum";*/
      /*color: #1890ff;*/
      /*text-align: center;*/
      /*font-size: 32px;*/
      /*box-sizing: border-box;*/
      /*position: absolute;*/
      /*display: block;*/
      /*background-color: #1890ff;*/
      /*border-radius: 100%;*/
      /*transform: scale(0.75);*/
      /*transform-origin: 50% 50%;*/
      /*opacity: 0.3;*/
      /*-webkit-animation: antSpinMove 1s infinite linear alternate;*/
      /*animation: antSpinMove 1s infinite linear alternate;*/
      /*top: 0;*/
      /*left: 0;*/
      /*width: 14px;*/
      /*height: 14px;*/
      font-weight: 400;
      list-style: none;
      color: #1890ff;
      text-align: center;
      font-size: 32px;
      box-sizing: border-box;
      position: absolute;
      display: block;
      border-radius: 100%;
      background: url('/assets/bi/images/ogo-icon.png');
      opacity: 1;
      -webkit-animation: antSpinMove 1s infinite linear alternate;
      animation: antSpinMove 1s infinite linear alternate;
      top: 0px;
      left: 3px;
      width: 40px;
      height: 40px;
    }

    &:nth-child(2) {
      font-weight: 400;
      font-family: 'DM Sans', sans-serif !important;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum';
      color: #1890ff;
      text-align: center;
      font-size: 32px;
      box-sizing: border-box;
      position: absolute;
      display: block;
      background-color: #1890ff;
      border-radius: 100%;
      transform: scale(0.75);
      transform-origin: 50% 50%;
      opacity: 0.3;
      -webkit-animation: antSpinMove 1s infinite linear alternate;
      animation: antSpinMove 1s infinite linear alternate;
      top: 0;
      right: 0;
      animation-delay: 0.4s;
      width: 14px;
      height: 14px;
    }

    &:nth-child(3) {
      font-weight: 400;
      font-family: 'DM Sans', sans-serif !important;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum';
      color: #1890ff;
      text-align: center;
      font-size: 32px;
      box-sizing: border-box;
      position: absolute;
      display: block;
      background-color: #1890ff;
      border-radius: 100%;
      transform: scale(0.75);
      transform-origin: 50% 50%;
      opacity: 0.3;
      -webkit-animation: antSpinMove 1s infinite linear alternate;
      animation: antSpinMove 1s infinite linear alternate;
      right: 0;
      bottom: 0;
      animation-delay: 0.8s;
      width: 14px;
      height: 14px;
    }

    &:nth-child(4) {
      font-weight: 400;
      font-family: 'DM Sans', sans-serif !important;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum';
      color: #1890ff;
      text-align: center;
      font-size: 32px;
      box-sizing: border-box;
      position: absolute;
      display: block;
      background-color: #1890ff;
      border-radius: 100%;
      transform: scale(0.75);
      transform-origin: 50% 50%;
      opacity: 0.3;
      -webkit-animation: antSpinMove 1s infinite linear alternate;
      animation: antSpinMove 1s infinite linear alternate;
      bottom: 0;
      left: 0;
      animation-delay: 1.2s;
      width: 14px;
      height: 14px;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@-webkit-keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

/* .lds-wedges > div > div {
    -webkit-transform-origin: 100px 100px;
    transform-origin: 100px 100px;
    -webkit-animation: lds-wedges 2.8s linear infinite;
    animation: lds-wedges 2.8s linear infinite;
    opacity: 0.8;
}
.lds-wedges > div > div > div {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 70px;
    height: 70px;
    border-radius: 70px 0 0 0;
    -webkit-transform-origin: 100px 100px;
    transform-origin: 100px 100px;
}
.lds-wedges > div div:nth-child(1) > div {
    background: #6463aa;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
.lds-wedges > div div:nth-child(1) {
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
}
.lds-wedges > div div:nth-child(2) > div {
    background: #9ba2d9;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
.lds-wedges > div div:nth-child(2) {
    -webkit-animation-duration: 0.933333333333333s;
    animation-duration: 0.933333333333333s;
}
.lds-wedges > div div:nth-child(3) > div {
    background: #d9dcf3;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
.lds-wedges > div div:nth-child(3) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
}
.lds-wedges > div div:nth-child(4) > div {
    background: #f7f7fc;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
.lds-wedges > div div:nth-child(4) {
    -webkit-animation-duration: 2.8s;
    animation-duration: 2.8s;
}
.lds-wedges {
    width: 100px !important;
    height: 100px !important;
    -webkit-transform: translate(-100px, -100px) scale(0.50) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(0.50) translate(50px, 50px);
} */

.ddp-wrap-loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;

  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(208, 209, 216, 0.5);
  }

  &.ddp-table {
    display: table;
    width: 100%;
    height: 100%;

    .ddp-view-loading {
      display: table-cell;
      text-align: center;
      vertical-align: middle;

      .ddp-data-date {
        text-align: left;
      }

      .ddp-box-loading.ddp-box-s {
        .ddp-box-in {
          width: 230px;
        }

        &.ddp-pop {
          position: relative;
          top: 0;
          left: 0;
          margin: 0;
        }
      }
    }
  }
}

.ddp-box-loading {
  &.ddp-pop {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -102px 0 0 -315px;

    &.ddp-disabled:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.7);
      content: '';
      z-index: 1;
    }

    .ddp-data-date {
      display: block;
      margin-top: 9px;
      color: #b7b9c2;
      font-size: 13px;
      font-style: italic;
      text-align: right;
    }

    .ddp-pop-buttons {
      position: relative;
      margin-top: 17px;
      text-align: center;
      z-index: 2;

      .ddp-txt-info {
        color: #3e4148;
        font-size: 14px;
        padding-bottom: 15px;
      }

      [class*='ddp-btn'] {
        margin: 0 2px;
      }
    }
  }

  display: inline-block;
  position: relative;
  padding: 30px;
  width: 630px;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  text-align: left;
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

  /* drop shadow */
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

  /* drop shadow */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

  /* drop shadow */
  z-index: 1;

  &.ddp-box-s {
    width: 380px;
    margin-left: -190px;

    &.ddp-pop {
      margin: -71px 0 0 -190px;
    }
  }

  .ddp-btn-cancel {
    display: inline-block;
    position: absolute;
    top: 30px;
    right: 30px;
    color: #d0d1d8;
    font-size: 13px;

    &:after {
      display: inline-block;
      content: '';
      width: 10px;
      height: 10px;
      margin-left: 8px;
      background: url(/assets/bi/images/btn_popclose.png) no-repeat;
      background-position: left -101px;
      vertical-align: middle;
    }
  }

  .ddp-btn-pause {
    display: inline-block;
    position: absolute;
    top: 30px;
    right: 30px;
    color: #d0d1d8;
    font-size: 13px;

    &:after {
      display: inline-block;
      content: '';
      width: 9px;
      height: 10px;
      margin-left: 8px;
      background: url(/assets/bi/images/icon_pause.png) no-repeat;
      vertical-align: middle;
    }
  }

  .ddp-btn-cancel:hover {
    color: #90969f;

    &:after {
      background-position: -11px -101px;
    }
  }

  .ddp-data-loading {
    display: inline-block;
    padding-bottom: 15px;
    color: $primary;
    font-size: 13px;
  }
}

.ddp-type-progressbar {
  display: inline-block;
  position: relative;
  height: 6px;
  width: 100%;
  border-radius: 4px;
  background-color: #e7e7ea;
  overflow: hidden;

  .ddp-data-progressbar {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 6px;
    background: url(/assets/bi/images/icon_loading.gif) repeat-x;
    background-position: -42px -2px;
  }
}

.ddp-type-progressbar2 {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 100%;
  border-radius: 4px;
  background-color: #e7e7ea;
  overflow: hidden;

  .ddp-data-progressbar {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 4px;
    background-color: $primary;

    &.type-failed {
      background-color: #cd5454;
    }
  }
}

@keyframes loading {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.ddp-box-loading.ddp-box-s {
  padding: 23px 0;
  text-align: center;

  .ddp-box-in {
    display: inline-block;
  }

  .ddp-icon-success,
  .ddp-btn-again,
  .ddp-icon-fail {
    display: inline-block;
    float: left;
    width: 55px;
    height: 55px;
    margin-right: 10px;
    background: url(/assets/bi/images/btn_progress.png) no-repeat;
    vertical-align: middle;
  }

  .ddp-btn-again {
    background-position: left -56px;
  }

  .ddp-icon-fail {
    background-position: left -112px;
  }

  .ddp-txt-success {
    font-size: 18px;
    color: $primary;
  }

  .ddp-txt-fail {
    font-size: 18px;
    color: $primary;
    color: #eb5f58;
  }

  .ddp-btn-close {
    display: inline-block;
    position: absolute;
    top: 13px;
    right: 12px;
    width: 10px;
    height: 10px;
    margin-left: 8px;
    background: url(/assets/bi/images/btn_popclose.png) no-repeat;
    background-position: left -101px;
    vertical-align: middle;
    content: '';

    &:hover {
      background-position: -11px -101px;
    }
  }

  .ddp-txt-info {
    text-align: left;
    overflow: hidden;

    &.ddp-again {
      padding-top: 15px;
    }
  }
}
