.tooltip-action-btn {
  display: flex;
  flex-flow: row;
  position: absolute;
  right: 0;
  z-index: 999;
  bottom: 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: var(--color-primary);
}

.action_btn {
  margin: 5px;
}

.action_btn--button {
  margin: 5px;
}

.move {
  right: 0 !important;
  transition: 0.2s linear;
}

#action-btn\ action_buttons__send {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg fill='white' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 2.02c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 12.55l-5.992-4.57h11.983l-5.991 4.57zm0 1.288l-6-4.629v6.771h12v-6.771l-6 4.629z'/%3E%3C/svg%3E");
}

#action-btn\ action_buttons__console {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg fill='white' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M22 4v10h-20v-10h20zm2-2h-24v14h24v-14zm-18 3h-3v2h3v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm-4 6h-10v2h10v-2zm4-3h-4v2h4v-2zm-14 0h-4v2h4v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm0 10l-4 4-4-4h8z'/%3E%3C/svg%3E");
}

#action-btn\ action_buttons__to_bank {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M2.001 9.352c0 1.873.849 2.943 1.683 3.943.031 1 .085 1.668-.333 3.183 1.748-.558 2.038-.778 3.008-1.374 1 .244 1.474.381 2.611.491-.094.708-.081 1.275.055 2.023-.752-.06-1.528-.178-2.33-.374-1.397.857-4.481 1.725-6.649 2.115.811-1.595 1.708-3.785 1.661-5.312-1.09-1.305-1.705-2.984-1.705-4.695-.001-4.826 4.718-8.352 9.999-8.352 5.237 0 9.977 3.484 9.998 8.318-.644-.175-1.322-.277-2.021-.314-.229-3.34-3.713-6.004-7.977-6.004-4.411 0-8 2.85-8 6.352zm20.883 10.169c-.029 1.001.558 2.435 1.088 3.479-1.419-.258-3.438-.824-4.352-1.385-.772.188-1.514.274-2.213.274-3.865 0-6.498-2.643-6.498-5.442 0-3.174 3.11-5.467 6.546-5.467 3.457 0 6.546 2.309 6.546 5.467 0 1.12-.403 2.221-1.117 3.074zm-7.424-2.429c0-.206-.061-.378-.184-.517-.125-.139-.318-.255-.584-.349-.242-.085-.393-.155-.455-.208-.129-.108-.133-.292.018-.394.075-.051.18-.077.312-.077.217 0 .428.046.627.14l.15-.524c-.221-.1-.475-.149-.768-.149-.336 0-.605.082-.807.244s-.303.37-.303.622c0 .39.273.675.822.858.184.061.311.121.385.179.156.123.146.338-.012.446-.082.056-.195.083-.342.083-.255 0-.504-.062-.752-.188l-.137.542c.244.123.527.184.846.184.371 0 .662-.083.869-.248.211-.164.315-.379.315-.644zm3.656.846l-.154-2.875h-.906l-.613 1.983-.508-1.983h-.895l-.184 2.875h.615l.102-2.321h.008s.352 1.439.59 2.273h.516c.396-1.209.631-1.968.699-2.273h.014c0 .406.021 1.18.067 2.321h.649zm2.451-.846c0-.209-.064-.386-.189-.527-.124-.14-.322-.259-.59-.353-.237-.084-.389-.154-.449-.205-.123-.103-.125-.273.016-.369.072-.049.176-.074.305-.074.232 0 .435.052.637.147l.158-.556-.012-.006c-.221-.1-.48-.15-.774-.15-.338 0-.612.083-.815.248-.205.165-.311.379-.311.634 0 .396.281.688.836.872.179.061.306.12.379.177.146.115.14.318-.012.42-.078.054-.19.081-.333.081-.274 0-.521-.072-.761-.195l-.145.574c.273.136.559.19.863.19.374 0 .67-.084.879-.251.211-.167.318-.388.318-.657z'/%3E%3C/svg%3E");
}
#action-btn\ action_buttons__to_bank:hover {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M2.001 9.352c0 1.873.849 2.943 1.683 3.943.031 1 .085 1.668-.333 3.183 1.748-.558 2.038-.778 3.008-1.374 1 .244 1.474.381 2.611.491-.094.708-.081 1.275.055 2.023-.752-.06-1.528-.178-2.33-.374-1.397.857-4.481 1.725-6.649 2.115.811-1.595 1.708-3.785 1.661-5.312-1.09-1.305-1.705-2.984-1.705-4.695-.001-4.826 4.718-8.352 9.999-8.352 5.237 0 9.977 3.484 9.998 8.318-.644-.175-1.322-.277-2.021-.314-.229-3.34-3.713-6.004-7.977-6.004-4.411 0-8 2.85-8 6.352zm20.883 10.169c-.029 1.001.558 2.435 1.088 3.479-1.419-.258-3.438-.824-4.352-1.385-.772.188-1.514.274-2.213.274-3.865 0-6.498-2.643-6.498-5.442 0-3.174 3.11-5.467 6.546-5.467 3.457 0 6.546 2.309 6.546 5.467 0 1.12-.403 2.221-1.117 3.074zm-7.424-2.429c0-.206-.061-.378-.184-.517-.125-.139-.318-.255-.584-.349-.242-.085-.393-.155-.455-.208-.129-.108-.133-.292.018-.394.075-.051.18-.077.312-.077.217 0 .428.046.627.14l.15-.524c-.221-.1-.475-.149-.768-.149-.336 0-.605.082-.807.244s-.303.37-.303.622c0 .39.273.675.822.858.184.061.311.121.385.179.156.123.146.338-.012.446-.082.056-.195.083-.342.083-.255 0-.504-.062-.752-.188l-.137.542c.244.123.527.184.846.184.371 0 .662-.083.869-.248.211-.164.315-.379.315-.644zm3.656.846l-.154-2.875h-.906l-.613 1.983-.508-1.983h-.895l-.184 2.875h.615l.102-2.321h.008s.352 1.439.59 2.273h.516c.396-1.209.631-1.968.699-2.273h.014c0 .406.021 1.18.067 2.321h.649zm2.451-.846c0-.209-.064-.386-.189-.527-.124-.14-.322-.259-.59-.353-.237-.084-.389-.154-.449-.205-.123-.103-.125-.273.016-.369.072-.049.176-.074.305-.074.232 0 .435.052.637.147l.158-.556-.012-.006c-.221-.1-.48-.15-.774-.15-.338 0-.612.083-.815.248-.205.165-.311.379-.311.634 0 .396.281.688.836.872.179.061.306.12.379.177.146.115.14.318-.012.42-.078.054-.19.081-.333.081-.274 0-.521-.072-.761-.195l-.145.574c.273.136.559.19.863.19.374 0 .67-.084.879-.251.211-.167.318-.388.318-.657z'/%3E%3C/svg%3E");
}

#action-btn\ action_buttons__console:hover {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg fill='%239ccc65' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M22 4v10h-20v-10h20zm2-2h-24v14h24v-14zm-18 3h-3v2h3v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm-4 6h-10v2h10v-2zm4-3h-4v2h4v-2zm-14 0h-4v2h4v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm0 10l-4 4-4-4h8z'/%3E%3C/svg%3E");
}

.pivot-view-head-axis-x {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px 0px 5px;
}

.pivot-view {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  overflow: hidden;
  background-color: #fff;
  font-size: 13px;
  text-align: left;
  line-height: 36px;
}


.pivot-view-form {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0px;
  left: 0;
  flex-direction: column;
  align-items: center;
  width: 30px;
}

.pivot-view-form > input {
  width: 15px !important;
  height: 30px !important;
}

.pivot-view-form-x-actions {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  top: 0px;
  width: 100px;
  left: 30px;
  height: 30px;
  flex-direction: row;
  align-items: center;

}

.pivot-view-form-x-actions-in {
  padding: 0px 20px 0px 3px;
  border-right: 2px solid black;
  margin-right: 5px;
  font-size: 15px;
}

.pivot-view-form-x-actions-in:hover {
  padding: 3px 20px 3px 3px;
  border-right: 2px solid green;
  margin-right: 5px;
}

.pivot-view-form-y-actions-in:hover {
  border-bottom: 2px solid green;
  cursor: pointer;
}

.pivot-view-field-x-undefined {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.pivot-left-animation {
  position: absolute;
  left: -116px;
  -moz-transition: left 1.3s;
  -webkit-transition: left 1.3s;
  -o-transition: left 1.3s;
  transition: left 1.3s;
}

.pivot-up-animation {
  position: absolute;
  top: 0px !important;
  -moz-transition: top 1.3s;
  -webkit-transition: top 1.3s;
  -o-transition: top 1.3s;
  transition: top 1.3s;
}

.pivot-view-form-y {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0px;
  left: 0px;
  flex-direction: column;
  align-items: center;
  width: 30px;
}

.pivot-view-form-x {
  position: relative;
  top: 30px;
  z-index: 1;
}

.pivot-view-form-y-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0px;
  left: 0px;
  flex-direction: column;
  align-items: center;
  width: 30px;
}

.pivot-view-form-y-wrapper > input {
  width: 15px !important;
  height: 30px !important;
}

.pivot-top-animation {
  top: -112px;
  position: absolute;
  -moz-transition: top 1.3s;
  -webkit-transition: top 1.3s;
  -o-transition: top 1.3s;
  transition: top 1.3s;
}

.pivot-right-animation {
  left: 0px;
  -moz-transition: left 1.3s;
  -webkit-transition: left 1.3s;
  -o-transition: left 1.3s;
  transition: left 1.3s;

}


.pivot-view-form-x-wrapper {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  top: 0px;
  left: 30px;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.pivot-view-form-x-wrapper > input {
  height: 30px !important;
}

.pivot-view-btn-x {
  background: var(--color-primary);
  color: white;
  border-radius: 3px;
  font-size: 13px;
  padding: 3px 7px 3px 7px;
}

.pivot-view-btn-y {
  background: var(--color-primary);
  color: white;
  border-radius: 3px;
  font-size: 13px;
  padding: 7px 3px 7px 3px;
}

.pivot-view-form-y-actions-in {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: sideways-right;
  border-bottom: 2px solid black;
  padding: 0px 0px 20px 0px;
  font-size: 15px;
  cursor: default;
}

.pivot-view-form-all {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 30px;
  left: 0;
  flex-direction: column;
  align-items: center;
  width: 30px;
  z-index: 1;
}

.pivot-view-form-all > input {
  width: 15px !important;
  height: 30px !important;
}

.pivot-view-form > input {
  width: 15px !important;
  height: 30px !important;
}


/*ROW*/
.pivot-view-head > .pivot-view-frozen > .pivot-view-row {
  background: #ecf0f5;
  color: #000 !important;
}

.pivot-view-head > .pivot-view-wrap > .pivot-view-row {
  background: #ecf0f5;
  color: #000;
}

/*CEll*/
.pivot-view-head > .pivot-view-frozen > .pivot-view-row > .pivot-view-cell {
  border: 1px solid #e2e5e8 !important;
  background: #ecf0f5 !important;
  color: #000 !important;
}

.pivot-view-head > .pivot-view-wrap > .pivot-view-row > .pivot-view-cell {
  border: 1px solid #e2e5e8 !important;
  background: #ecf0f5;
  color: #000;
}

.pivot-view-head > .pivot-view-wrap > .pivot-view-row > .pivot-view-row > .pivot-view-cell {
  border: 1px solid #e2e5e8 !important;
  background: #ecf0f5;
  color: #000;
}

.pivot-view div {
  box-sizing: border-box;
}

.pivot-view-body
.pivot-view-body .pivot-view-frozen.ddp-header-hide,
.pivot-view-head.ddp-header-hide {
  visibility: hidden;
}

.pivot-view-row.ddp-font11 {
  font-size: 11px;
}

.pivot-view-row.ddp-font13 {
  font-size: 13px;
}

.pivot-view-row.ddp-font15 {
  font-size: 15px;
}

.pivot-view-row.ddp-font-italic {
  font-style: italic;
}

.pivot-view-row.ddp-font-bold {
  font-weight: bold;
}


/*.pivot-view-row.ddp-valign-top div { line-height: 14px !important; }*/


/*.pivot-view-row.ddp-valign-middle div { line-height: 30px !important; }*/


/*.pivot-view-row.ddp-valign-bottom div { line-height: 43px !important; }*/

.pivot-view-row.ddp-valign-top .pivot-view-cell {
  display: flex;
  align-items: flex-start;
}

.pivot-view-row.ddp-valign-middle .pivot-view-cell {
  display: flex;
  align-items: center;
}

.pivot-view-row.ddp-valign-bottom .pivot-view-cell {
  display: flex;
  align-items: flex-end;
}


/*.pivot-view-row.ddp-txt-left { text-align: left; }*/


/*.pivot-view-row.ddp-txt-center { text-align: center; }*/


/*.pivot-view-row.ddp-txt-right { text-align: right; }*/

.pivot-view-row.ddp-txt-left .pivot-view-cell {
  display: flex;
  justify-content: flex-start;
}

.pivot-view-row.ddp-txt-center .pivot-view-cell {
  display: flex;
  justify-content: center;
}

.pivot-view-row.ddp-txt-right .pivot-view-cell {
  display: flex;
  justify-content: flex-end;
}

.pivot-view .pivot-view-body .pivot-view-frozen {
  line-height: 30px !important;
}

.pivot-view .pivot-view-row {
  position: absolute;
}

.pivot-view .pivot-view-row .pivot-view-cell {
  position: absolute;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #f0f0f0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 8px;
}

.pivot-view .pivot-view-head {
  position: absolute;
  overflow: hidden;
  border-right: none;
  color: #7b868c;
  border-top: 1px solid #f0f0f0;
}

.pivot-view .pivot-view-head .pivot-view-wrap {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.pivot-view .pivot-view-head .pivot-view-frozen {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.pivot-view .pivot-view-head .pivot-view-frozen .pivot-view-cell {
  border-bottom: none;
}

.pivot-view .pivot-view-head .pivot-view-frozen .pivot-view-row:last-child .pivot-view-cell {
  border-bottom: 1px solid #f0f0f0;
}

.pivot-view.pivot-view-scroll-horizontal .pivot-view-head {
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.pivot-view .pivot-view-body {
  position: absolute;
  overflow: auto;
}

.pivot-view .pivot-view-body.header-none {
  top: 0px !important;
}

.pivot-view .pivot-view-body .pivot-view-wrap.header-none {
  left: 0px !important;
}

.pivot-view .pivot-view-body .pivot-view-wrap {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
}

.pivot-view .pivot-view-body .pivot-view-wrap .pivot-view-row.odd {
  background-color: #f9f9f9;
}

.pivot-view .pivot-view-body .pivot-view-frozen {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
}

.pivot-view .pivot-view-body .pivot-view-wrap .pivot-view-cell.txt-right {
  text-align: right;
}

.pivot-view .pivot-view-head .pivot-view-wrap .pivot-view-cell.txt-right {
  text-align: right;
}


/* Add Click Event Style - by eltriny */

.pivot-view .pivot-view-body .pivot-view-wrap .pivot-view-cell.numeric {
  /*text-align: right;*/
  color: #297bb8;
}

.pivot-view .pivot-axis-x:hover,
.pivot-view .pivot-axis-y:hover {
  border: 1px solid #6e97fa !important;
}

.pivot-view .pivot-axis-selected-body,
.pivot-axis-selected-body div,
.pivot-view .pivot-body-hover,
.pivot-body-hover div {
  background-color: #6e97fa !important;
  color: white !important;
}

.pivot-view .pivot-body-selected,
.pivot-body-selected div,
.pivot-view .pivot-axis-selected {
  background-color:var(--color-primary) !important;
  color: white !important;
}


/* Add Click Event Style - by eltriny */

.pivot-view .pivot-view-body .span.txt-bottom {
  position: relative
}

.pivot-view .pivot-view-body.txt-right {
  text-align: right;
  display: block;
}

.pivot-view span {
  position: absolute;
  height: 30px;
  line-height: 30px;
}

.pivot-view span.remark-right {
  text-align: right;
  display: block;
}

.pivot-view span.remark-left {
  padding-left: 10px;
  display: block;
}

.pivot-view .pivot-view-body .pivot-total {
  position: absolute;
  overflow: hidden;
}

.pivot-view .pivot-view-body .pivot-total .pivot-total-frozen {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.pivot-view .pivot-view-body .pivot-total .pivot-total-wrap {
  position: absolute;
  z-index: 1;
  background-color: #fff;
}

.pivot-resizable-handle {
  cursor: col-resize;
  position: absolute;
  font-size: 0.1px;
  display: block;
  width: 8px;
  right: 0px;
  top: 0px;
  height: 100%;
}
