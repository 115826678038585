@charset "utf-8";

/***************************************************************************
	button
*************************************************************************/

a {
  &.ddp-btn-type {
    position: relative;
    display: inline-block;
    padding: 11px 10px;
    min-width: 206px;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #afb3b7;

    &:hover {
      color: #fff;
      border: 1px solid #f28400;
      background-color: #f28400;
    }

    &.ddp-bg-orange {
      border: 1px solid #ff8b00;
      background-color: #ff8b00;
      color: #fff;

      &:hover {
        border: 1px solid #f28400;
        background-color: #f28400;
      }
    }

    &.ddp-btn-disabled {
      background-color: #fff;
      color: #707070;
      border: 1px solid #afb3b7;
      cursor: no-drop;

      &:hover {
        background-color: #fff;
        color: #707070;
        border: 1px solid #afb3b7;
        cursor: no-drop;
      }
    }
  }

  &.ddp-btn-buttons2 {
    display: inline-block;
    padding: 7px 0;
    width: 115px;
    border-radius: 2px;
    background-color: #f3f3f5;
    border: 1px solid #8086bd;
    color: $primary;
    font-weight: bold;
    font-size: 12px;
    text-align: center;

    &:hover {
      background-color: $primary;
      border: 1px solid $primary;
      color: #fff;

      em.ddp-icon-btndone {
        background-position: -16px -163px;
      }
    }
  }

  &.ddp-btn-buttons3 {
    display: inline-block;
    padding: 7px 0;
    width: 115px;
    border-radius: 2px;
    border: 1px solid #b8bdc4;
    color: #4b525b;
    font-weight: bold;
    font-size: 12px;
    text-align: center;

    &:hover {
      border: 1px solid #a1a6ad;
      color: #4a525a;
    }

    &.type-normal {
      font-weight: normal;
    }
  }

  &.ddp-btn-type3,
  &.ddp-btn-type4 {
    display: inline-block;
    padding: 6px 0;
    border-radius: 2px;
    text-align: center;
    min-width: 85px;
    font-size: 12px;
  }

  &.ddp-btn-type3 {
    color: #90979f;
    border: 1px solid #d2d2d7;
  }

  &.ddp-btn-type4 {
    color: #fff;
    border: 1px solid #90969f;
    background-color: #90969f;

    &:hover {
      border: 1px solid #4b515b;
      background-color: #4b515b;
      color: #fff;
    }

    &.ddp-disabled {
      background-color: #d0d1d8;
      border: 1px solid #d0d1d8;
      cursor: no-drop;
    }
  }

  &.ddp-btn-buttons4 {
    display: inline-block;
    padding: 6px 0;
    width: 130px;
    border-radius: 2px;
    border: 1px solid #d0d1d8;
    color: #90969f;
    font-size: 12px;
    text-align: center;

    &:hover {
      border: 1px solid #b7b9c2;
      color: #4b515b;
    }

    &.ddp-bg-black {
      background-color: #557daa;
      border: 1px solid #557daa;
      color: #fff;

      &:hover {
        background-color: #3e6192;
        border: 1px solid #3e6192;
      }
    }

    .ddp-icon-add {
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: -42px -22px;
    }

    .ddp-icon-edit {
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_widget.png) no-repeat;
      background-position: -41px -131px;
    }
  }
}

.ddp-btn-pop {
  display: inline-block;
  min-width: 85px;
  padding: 6px 9px;
  border-radius: 2px;
  border: 1px solid #b6b9c2;
  text-align: center;
  color: #4b515b;
  font-size: 13px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid #90969f;
    color: #4b515b;
  }

  &.ddp-disabled {
    border: 1px solid #e7e7ea;
    color: #d0d1d8;
    cursor: no-drop;
  }

  em.ddp-btn-reset {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 15px;
    height: 13px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_reset.png) no-repeat;
    background-position: -16px -20px;
    vertical-align: middle;
  }

  &.ddp-bg-black {
    background-color: #557daa;
    border: 1px solid #557daa;
    color: #fff;

    &:hover {
      background-color: #3e6192;
      border: 1px solid #3e6192;
      color: #fff;
    }

    .ddp-icon-view {
      display: inline-block;
      width: 13px;
      height: 11px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_selection.png) no-repeat;
      background-position: -14px -43px;
      vertical-align: middle;
    }

    &.ddp-disabled {
      background-color: #d0d1d8;
      border: 1px solid #d0d1d8;
      color: #fff;
      cursor: no-drop;
    }

    em {
      &.ddp-icon-checked {
        display: inline-block;
        position: relative;
        width: 11px;
        margin-right: 5px;
        height: 9px;
        background: url(/assets/bi/images/icon_select2.png) no-repeat;
        background-position: -27px top;
        vertical-align: middle;
      }

      &.ddp-icon-add2 {
        display: inline-block;
        position: relative;
        width: 13px;
        height: 13px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: -42px -22px;
        vertical-align: middle;
      }

      &.ddp-icon-link-plus {
        position: relative;
        top: -1px;
        margin-right: 5px;
        background-position: -30px -2px;
        vertical-align: middle;
      }
    }
  }

  &.ddp-bg-black2 {
    background-color: #557daa;
    border: 1px solid #557daa;
    color: #fff;

    &:hover {
      background-color: #3e6192;
      border: 1px solid #3e6192;
      color: #fff;
    }

    &.ddp-disabled {
      background-color: #d0d1d8;
      border: 1px solid #d0d1d8;
      color: #fff;
      cursor: no-drop;
    }
  }

  &.ddp-bg-gray {
    background-color: #90969f;
    border: 1px solid #90969f;
    color: #fff;

    &:hover {
      background-color: #4b515b;
      border: 1px solid #4b515b;
      color: #fff;
    }

    &.ddp-disabled {
      background-color: #d0d1d8;
      border: 1px solid #d0d1d8;
      color: #fff;
      cursor: no-drop;

      &:hover {
        background-color: #d0d1d8;
        border: 1px solid #d0d1d8;
        color: #fff;
        cursor: no-drop;
      }
    }
  }
}

a {
  &.ddp-btn-type-popup {
    position: relative;
    display: inline-block;
    padding: 9px 10px;
    min-width: 206px;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #b6b9c2;
    color: #4b515b;

    &:hover {
      color: #707070;
      border: 1px solid #90969f;
    }

    &.ddp-bg-black {
      background-color: #557daa;
      border: 1px solid #557daa;
      color: #fff;

      &:hover {
        background-color: #3e6192;
        border: 1px solid #3e6192;
        color: #fff;
      }

      &.ddp-disabled {
        background-color: #d0d1d8;
        border: 1px solid #d0d1d8;
        cursor: no-drop;

        &:hover {
          background-color: #d0d1d8;
          border: 1px solid #d0d1d8;
          cursor: no-drop;
        }
      }
    }

    .ddp-icon-add {
      display: inline-block;
      margin-right: 7px;
      background-position-x: -28px;
    }
  }

  &.ddp-btn-type-popup2 {
    position: relative;
    display: inline-block;
    padding: 6px 10px;
    min-width: 87px;
    font-size: 13px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #d0d1d7;
    color: #90969f;
    box-sizing: border-box;

    &:hover {
      color: #707070;
      border: 1px solid #b7b9c1;
    }

    &.ddp-bg-black {
      background-color: #557daa;
      border: 1px solid #557daa;
      color: #fff;

      &:hover {
        background-color: #3e6192;
        border: 1px solid #3e6192;
        color: #fff;
      }

      &.ddp-disabled {
        background-color: #d0d1d8;
        border: 1px solid #d0d1d8;
        cursor: no-drop;

        &:hover {
          background-color: #d0d1d8;
          border: 1px solid #d0d1d8;
          cursor: no-drop;
        }
      }
    }
  }
}

.ddp-btn-selection {
  display: inline-block;
  padding: 7px 10px 8px 10px;
  border-radius: 3px;
  background-color: #557daa;
  color: #fff;
  font-size: 13px;

  &:hover {
    background-color: #3e6192;
    color: #fff;

    .ddp-icon-edit3 {
      background-position-x: 0;
    }
  }

  .ddp-icon-edit3 {
    margin-right: 6px;
  }

  .ddp-icon-view {
    display: inline-block;
    width: 13px;
    height: 11px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_selection.png) no-repeat;
    background-position: -14px -43px;
    vertical-align: middle;
  }

  .ddp-icon-check {
    display: inline-block;
    width: 12px;
    height: 8px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_select2.png) no-repeat;
    background-position: -27px top;
  }

  .ddp-icon-delete {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_edit2.png) no-repeat;
    background-position: -30px -11px;
    vertical-align: middle;
  }

  .ddp-icon-create {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    background: url(/assets/bi/images/btn_minus_plus.png) no-repeat;
    background-position: -20px -2px;
    vertical-align: middle;
  }

  .ddp-icon-close {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    background: url(/assets/bi/images/btn_sclose.png) no-repeat;
    background-position: 0 -121px;
  }

  &.ddp-gray {
    background-color: #e7e7ea;
    color: #4b525b;

    &:hover {
      background-color: #d0d1d8;
      color: #4b525b;
    }

    em.ddp-icon-reset {
      display: inline-block;
      width: 14px;
      height: 13px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_reset.png) no-repeat;
      background-position: -16px -20px;
      vertical-align: middle;
    }

    .ddp-icon-view {
      background-position: -28px -43px;
    }

    em {
      &.ddp-icon-add2 {
        display: inline-block;
        position: relative;
        width: 13px;
        height: 13px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: left -22px;
        vertical-align: middle;
      }

      &.ddp-icon-plus {
        display: inline-block;
        position: relative;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_add.png) no-repeat;
        background-position: -22px -66px;
        vertical-align: middle;
      }
    }
  }

  &.ddp-reject {
    background-color: #e35252;

    &:hover {
      background-color: #c63b3b;
      color: #fff;
    }
  }

  &.ddp-line {
    color: #4b515b;
    border: 1px solid #b6b9c2;
    background: none;

    &.ddp-s {
      font-size: 12px;
      padding: 4px 10px 4px 10px;
    }

    .ddp-icon-view {
      background-position: -28px -43px;
    }

    &:hover {
      border: 1px solid #90969f;
      background: none;
      color: #4b515b;

      .ddp-icon-key {
        background-position: -13px top;
      }

      .ddp-icon-clone {
        background-position-x: -14px;
      }
    }

    .ddp-icon-key {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_key.png) no-repeat;
      vertical-align: middle;
    }

    .ddp-icon-clone {
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 5px;
      background: url(/assets/bi/images/icon_clone.png) no-repeat;
      vertical-align: middle;
    }

    &:hover .ddp-icon-view {
      background-position: -42px -43px;
    }
  }
}

.ddp-btn-bg {
  .ddp-icon-add {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_add.png) no-repeat;
    background-position: left -22px;
    vertical-align: middle;
  }

  .ddp-icon-editor {
    display: inline-block;
    width: 14px;
    height: 11px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_editor.png) no-repeat;
    vertical-align: middle;
  }

  padding: 9px 12px;
  border-radius: 2px;
  background-color: #e7e7ea;
  color: #4b515b;

  &:hover {
    background-color: #d0d1d8;
  }

  &.ddp-disabled {
    background-color: #e7e7ea;
    opacity: 0.3;
    cursor: no-drop;

    &:hover {
      background-color: #e7e7ea;
      opacity: 0.3;
      cursor: no-drop;
    }
  }

  .ddp-icon-option {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_option.png) no-repeat;
    background-position: -16px top;
    vertical-align: middle;
  }

  .ddp-icon-link-down {
    background-position: -14px -230px;
  }

  .ddp-icon-spin-loading {
    display: none;
    margin-right: 6px;
  }

  &.ddp-load {
    .ddp-icon-spin-loading {
      display: inline-block;
    }

    .ddp-icon-link-down {
      display: none;
    }
  }
}

/***************************************************************************
	bt button
*************************************************************************/

/***************************************************************************
	 link button
*************************************************************************/

.ddp-btn-link:hover em {
  &.ddp-icon-link-edit2 {
    background-position-x: -52px;
  }

  &.ddp-icon-link-down {
    background-position-x: -14px;
  }
}

/***************************************************************************
	line button
*************************************************************************/

a {
  &.ddp-btn-line {
    display: inline-block;
    padding: 6px 10px;
    min-width: 85px;
    border-radius: 2px;
    border: 1px solid #b6b9c2;
    color: #4b515b;
    font-size: 13px;
    text-align: center;
    box-sizing: border-box;

    &:hover {
      border: 1px solid #90969f;
      color: #4b515b;
    }

    &.ddp-disabled {
      border: 1px solid #e7e7ea;
      color: #d0d1d8;
      cursor: no-drop;
    }

    &.ddp-full {
      width: 100%;
      box-sizing: border-box;
    }

    > [class*='ddp-icon'] {
      margin-right: 5px;
    }

    em {
      &.ddp-icon-go {
        display: inline-block;
        width: 13px;
        height: 11px;
        margin-right: 5px;
        background: url(/assets/bi/images/icon_selection.png) no-repeat;
        background-position: -28px -43px;
      }

      &.ddp-view {
        display: inline-block;
        width: 7px;
        height: 4px;
        margin-left: 5px;
        background: url('/assets/bi/images/icon_select.png') no-repeat;
        background-position: -8px top;
        vertical-align: middle;
      }

      &.ddp-icon-refresh {
        display: inline-block;
        width: 14px;
        height: 13px;
        margin-right: 10px;
        background: url(/assets/bi/images/btn_refresh.png) no-repeat;
        background-position: -15px -34px;
      }
    }

    &:hover em.ddp-icon-refresh {
      background-position: -30px -34px;
    }

    em.ddp-icon-delete {
      background-position: -24px -21px;
    }

    &:hover em.ddp-icon-delete {
      background-position: left -21px;
    }

    &.ddp-type {
      min-width: inherit;
      border: 1px solid #90969f;
      color: #4b515b;
      font-size: 13px;

      &:hover {
        background-color: #d0d1d8;
      }
    }
  }

  &.ddp-btn-line-s {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #d0d1d8;
    border-radius: 2px;
    font-size: 14px;
    color: #90969f;
    text-align: center;
    box-sizing: border-box;
    vertical-align: middle;

    &:hover {
      border: 1px solid #b7b9c2;
      color: #4b515b;
    }

    &.ddp-disabled {
      border: 1px solid #e7e7ea;
      color: #d0d1d8;
      cursor: no-drop;

      &:hover {
        border: 1px solid #e7e7ea;
        color: #d0d1d8;
        cursor: no-drop;
      }
    }

    em {
      margin-right: 5px;
      vertical-align: middle;

      &.ddp-icon-upload,
      &.ddp-icon-download {
        display: inline-block;
        background: url(/assets/bi/images/icon_control.png) no-repeat;
      }

      &.ddp-icon-upload {
        width: 11px;
        height: 12px;
        background-position: left -244px;
      }

      &.ddp-icon-download {
        width: 13px;
        height: 13px;
        background-position: left -230px;
      }
    }

    &:hover em {
      &.ddp-icon-upload {
        background-position-x: -12px;
      }

      &.ddp-icon-download {
        background-position-x: -14px;
      }
    }

    em.ddp-icon-add2 {
      display: inline-block;
      width: 13px;
      height: 13px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: -84px -22px;
    }
  }
}

/*.ddp-btn-line2:hover em.ddp-icon-btnsource {background-position:-12px 0;}*/

.ddp-btn-line2 {
  color: #4b515b;
  border: 1px solid #90969f;
  &:hover {
    em {
      &.ddp-icon-spacelist {
        background-position: -15px -73px;
      }

      &.ddp-icon-btnoption {
        background-position: -15px -16px;
      }
    }
  }

  display: inline-block;
  min-width: 149px;
  padding: 6px 12px;
  border: 1px solid #b6b9c2;
  border-radius: 2px;
  color: #4b515b;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
}

/***************************************************************************
	solid button
*************************************************************************/

a.ddp-btn-solid {
  display: inline-block;
  padding: 7px 13px 6px 13px;
  min-width: 85px;
  border-radius: 2px;
  border: 1px solid #90969f;
  background-color: #90969f;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  vertical-align: middle;

  &:hover {
    border: 1px solid #4b515b;
    background-color: #4b515b;
  }

  &.ddp-disabled {
    border: 1px solid #d0d1d8;
    background-color: #d0d1d8;
    cursor: no-drop;

    &:hover {
      border: 1px solid #d0d1d8;
      background-color: #d0d1d8;
      cursor: no-drop;
    }
  }

  &.ddp-full {
    width: 100%;
    box-sizing: border-box;
  }

  &.ddp-bg-black {
    border: 1px solid #557daa;
    background-color: #557daa;

    &:hover {
      border: 1px solid #3e6192;
      background-color: #3e6192;
      color: #fff;
    }
  }
}

.ddp-btn-solid2 {
  display: inline-block;
  min-width: 110px;
  padding: 6px 10px;
  border: 1px solid #8f949d;
  background-color: #8f949d;
  border-radius: 2px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  box-sizing: border-box;

  &:hover {
    background-color: #4b525b;
    color: #fff;
  }

  &.ddp-dark {
    border: 1px solid #516197;
    background-color: #516197;

    &:hover {
      border: 1px solid #34416d;
      background-color: #34416d;
    }
  }
}

a.ddp-btn-solid2.ddp-disabled {
  border: 1px solid #d0d1d8;
  background-color: #d0d1d8;
  cursor: no-drop;

  &:hover {
    border: 1px solid #d0d1d8;
    background-color: #d0d1d8;
    cursor: no-drop;
  }
}

.ddp-btn-solid2 .ddp-icon-add2 {
  display: inline-block;
  position: relative;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  background: url(/assets/bi/images/icon_add.png) no-repeat;
  background-position: -42px -22px;
  vertical-align: middle;
}

/***************************************************************************
	close button
*************************************************************************/

a.ddp-btn-popup-close {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(/assets/bi/images/btn_close.png) no-repeat;

  &:hover {
    background-position: left -17px;
  }
}

/***************************************************************************
	토글버튼
*************************************************************************/

.ddp-link-toggle {
  display: inline-block;
  padding: 5px 16px;
  font-size: 14px;
}

/**************************************************************
    box button
**************************************************************/

.ddp-list-buttons {
  display: inline-block;
  border-radius: 2px;
  overflow: hidden;

  li {
    position: relative;
    float: left;
    min-width: 112px;
    padding: 8px 10px;
    color: #4c515a;
    font-size: 12px;
    text-align: center;
    border-left: 1px solid #fff;
    background-color: #e7e7ea;
    cursor: pointer;
    box-sizing: border-box;

    &.ddp-selected:before {
      position: relative;
      top: -2px;
      display: inline-block;
      margin-right: 4px;
      width: 14px;
      height: 10px;
      background: url(/assets/bi/images/icon_select2.png) no-repeat;
      vertical-align: middle;
      content: '';
    }

    &:hover {
      display: inline-block;
      background-color: #dbdce1;
    }

    &.ddp-selected {
      color: #fff;
      background-color: #4c515a;
    }

    &.ddp-disabled {
      opacity: 0.5;
      cursor: no-drop;

      &:hover {
        cursor: no-drop;
        background: #e7e7ea;
      }
    }
  }

  &.ddp-table {
    display: table;
    margin-bottom: 6px;

    li {
      display: table-cell;
      float: inherit;
      padding: 0 10px;
      height: 30px;
      vertical-align: middle;
      max-width: 112px;
      box-sizing: Border-box;

      &:before {
        display: none;
      }

      .ddp-txt-check {
        display: inline-block;
        position: relative;
        font-size: 12px;
        white-space: normal;
      }

      &.ddp-selected .ddp-txt-check {
        padding-left: 20px;

        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          display: inline-block;
          width: 14px;
          height: 10px;
          margin: -5px 0 0 0;
          background: url(/assets/bi/images/icon_select2.png) no-repeat;
          vertical-align: middle;
          content: '';
        }
      }
    }
  }
}

/**************************************************************
    link
**************************************************************/

.ddp-type-link3 {
  color: #90969f;
  text-decoration: underline;
  opacity: 0.9;

  &:hover {
    color: #4b515b;
    text-decoration: underline;
    opacity: 1;
  }

  em.ddp-icon-linkplus {
    display: inline-block;
    position: relative;
    width: 7px;
    height: 7px;
    margin-right: 3px;
    background: url(/assets/bi/images/icon_link.png) no-repeat;
    vertical-align: middle;
  }

  &:hover em.ddp-icon-linkplus {
    background-position: left -8px;
  }

  em.ddp-icon-linkdelete {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 5px;
    background: url(/assets/bi/images/btn_sclose.png) no-repeat;
    background-position: left -86px;
    vertical-align: middle;
  }
}

.ddp-link-detail {
  text-decoration: underline;
  font-style: italic;

  &:hover {
    text-decoration: underline;
    font-style: italic;
  }

  em.ddp-icon-window {
    margin-left: 5px;
  }

  &.ddp-type {
    font-style: normal;
    text-decoration: none;

    &:hover {
      font-weight: bold;
      text-decoration: underline;
    }

    em.ddp-icon-window {
      display: none;
    }

    &:hover em.ddp-icon-window {
      display: inline-block;
    }
  }
}

.ddp-link-setting {
  color: #90969f;
  font-size: 14px;
  font-weight: normal;

  em.ddp-icon-setting {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 14px;
    height: 14px;
    margin-right: 5px;
    background: url(/assets/bi/images/icon_option.png) no-repeat;
    background-position: left -16px;
    vertical-align: middle;
  }

  &:hover {
    color: #4b515b;

    em.ddp-icon-setting {
      background-position: -15px -16px;
    }
  }
}

/**************************************************************
	그리드 수 보기, row
**************************************************************/

.ddp-view-option {
  display: inline-block;
  position: relative;
  padding: 0 0 0 20px;
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
  color: #4b515b;

  em.ddp-icon-option {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 15px;
    margin-top: -8px;
    background: url(/assets/bi/images/icon_option.png) no-repeat;
  }

  &:hover em.ddp-icon-option,
  &.ddp-selected em.ddp-icon-option {
    background-position: -16px top;
  }

  input {
    display: none;
    width: 78px;
    padding: 3px 0;
    border: none;
    border-bottom: 1px solid #90969f;
  }

  span.ddp-data-value {
    display: inline-block;
    min-width: 40px;
    font-size: 13px;
    font-weight: normal;
    color: #4b515b;
  }

  input.ddp-data {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
    text-align: center;
    border-bottom: 1px solid #d7d8dc;
  }

  &.ddp-selected {
    span.ddp-data-value {
      display: none;
    }

    input {
      display: block;
    }
  }
}

/**************************************************************
    탭
**************************************************************/

.ddp-wrap-tab {
  position: relative;
  margin-bottom: 15px;

  &:after {
    display: inline-block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ddd;
  }

  .ddp-btn-bg.ddp-config {
    position: absolute;
    top: 0;
    right: 0;
  }
}

ul {
  &.ddp-ui-tab {
    li {
      position: relative;
      float: left;
      min-width: 185px;

      a {
        display: block;
        position: relative;
        padding: 13px 13px 9px 13px;
        height: 42px;
        color: #b7b9c2;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;

        &:hover {
          background-color: #f6f6f7;
        }
      }

      &.ddp-selected a {
        font-weight: bold;
        font-size: 13px;
        border: 1px solid #d7d8dd;
        border-top: none;
        color: #4b515b;

        &:before {
          display: inline-block;
          border-top: 2px solid #444;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          content: '';
        }

        &:after {
          display: inline-block;
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          z-index: 1;
          content: '';
          border-bottom: 1px solid #fff;
        }

        span.ddp-data-num {
          color: $primary;
        }
      }
    }

    &.ddp-type li {
      a {
        padding: 10px 13px 10px 13px;
        height: 32px;
        font-size: 13px;
        text-align: left;
        color: #4b515b;
        font-weight: normal;
        border-right: 1px solid #d7d8dd;
      }

      &.ddp-selected a {
        font-weight: bold;
        border: none;
        border-right: 1px solid #d7d8dd;
      }
    }

    li em.ddp-icon-infotab {
      display: inline-block;
      position: relative;
      width: 32px;
      height: 32px;
      border-right: 1px solid #d7d8dd;
      cursor: pointer;

      &:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 13px;
        height: 13px;
        margin: -7px 0 0 -7px;
        background: url(/assets/bi/images/icon_info.png) no-repeat;
        background-position: -28px -30px;
      }
    }

    &.ddp-tab2 li {
      width: 50%;
    }

    &.ddp-type2 li a {
      border-top: 1px solid #d7d8dd;
    }
  }

  &.ddp-list-tab2 {
    overflow: hidden;

    li {
      float: left;
      width: 50%;

      a {
        display: block;
        position: relative;
        padding: 18px 0 18px 0;
        text-align: center;
        color: #d0d1d8;
        font-size: 12px;
        font-weight: bold;
      }

      &.ddp-selected a {
        color: $primary;
      }

      a:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        height: 14px;
        margin-top: -7px;
        border-left: 1px solid #eaebed;
        content: '';
      }

      &:first-of-type a:before {
        display: none;
      }
    }
  }
}

/*ul.ddp-ui-tab.ddp-type li a.ddp-btn-close {height:11px; }*/

/*ul.ddp-ui-tab.ddp-type li a.ddp-btn-close:hover {background-color:#fff !important;}*/

/**************************************************************
	more button
**************************************************************/

.ddp-wrap-morebutton {
  position: relative;
  cursor: pointer;

  em.ddp-icon-more {
    display: inline-block;
    padding: 4px;
    font-size: 0;

    &:before {
      display: inline-block;
      content: '';
      width: 2px;
      height: 9px;
      background: url(/assets/bi/images/icon_more2.png) no-repeat;
    }

    &:hover:before {
      background-position: -3px top;
    }
  }

  &.ddp-selected em.ddp-icon-more:before {
    background-position: -3px top;
  }

  .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    top: 20px;
    right: 0;
    white-space: nowrap;
  }

  &.ddp-selected .ddp-wrap-popup2 {
    display: block;
    z-index: 20;
  }

  .ddp-wrap-popup2 .ddp-label {
    display: block;
    padding: 10px 14px 5px 14px;
    color: #b7b9c2;
    font-size: 12px;
    cursor: default;
  }

  &.ddp-selected .ddp-ui-tooltip-info {
    display: none;
  }

  .ddp-ui-tooltip-info.ddp-down em.ddp-icon-view-top {
    right: 14px;
  }
}

/**************************************************************
	정렬
**************************************************************/

.ddp-ui-sort {
  position: relative;
  float: left;
  padding: 13px 16px 13px 16px;
  margin-top: 6px;
  margin-right: -16px;
  cursor: pointer;

  .ddp-ui-tooltip-info {
    display: none;
    top: 100%;
  }

  &:hover .ddp-ui-tooltip-info {
    display: block;
    left: inherit;
    right: 0;

    em.ddp-icon-view-top {
      right: 22px;
    }
  }

  .ddp-wrap-popup2 {
    display: none;
    position: absolute;
    top: 37px;
    right: 0;
    white-space: nowrap;
    min-width: 140px;
  }

  &.ddp-selected {
    .ddp-wrap-popup2 {
      display: block;
    }

    &:hover .ddp-ui-tooltip-info {
      display: none;
    }
  }

  a.ddp-icon-sort {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(/assets/bi/images/icon_sort.png) no-repeat;
    background-position: left 0;
  }

  &:hover a.ddp-icon-sort,
  &.ddp-selected a.ddp-icon-sort {
    background-position: -16px 0;
  }
}

/**************************************************************
	Toggle Switch
**************************************************************/

ul.ddp-list-tab-button {
  float: left;
  position: relative;
  margin: 15px 0 0 14px;
  border-radius: 2px;
  border: 1px solid #b7b9c3;

  li {
    float: left;
    position: relative;

    &:first-of-type a {
      border-left: none;
    }

    a {
      display: block;
      width: 23px;
      height: 23px;
      position: relative;
      box-sizing: border-box;
      border-left: 1px solid #b7b9c3;

      em {
        &.ddp-icon-grid {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -7px 0 0 -7px;
        }

        &.ddp-icon-listgrid {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -8px;
        }

        &.ddp-icon-lineage {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -7px 0 0 -9px;
        }
      }

      &:hover em {
        &.ddp-icon-grid {
          background-position: -14px top;
        }

        &.ddp-icon-listgrid {
          background-position: -16px -14px;
        }

        &.ddp-icon-lineage {
          background-position: -16px -26px;
        }
      }
    }

    &.ddp-selected a {
      background-color: #4b515b;

      &:before {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border: 2px solid #4b515b;
        content: '';
      }

      em {
        &.ddp-icon-grid {
          background-position: -28px top;
        }

        &.ddp-icon-listgrid {
          background-position: -32px -14px;
        }

        &.ddp-icon-lineage {
          background-position: -32px -26px;
        }
      }
    }

    .ddp-ui-tooltip-info {
      top: 30px;
      left: 0;

      em.ddp-icon-view-top {
        left: 15px;
      }
    }

    a {
      + .ddp-ui-tooltip-info {
        display: none;
      }

      &:hover:after {
        display: inline-block;
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 1px solid #4b515b;
      }
    }

    &:first-of-type a:after {
      border-radius: 2px 0 0 2px;
    }

    &:last-of-type a:after {
      border-radius: 0 2px 2px 0;
    }

    &:first-of-type a:hover:before {
      border-radius: 2px 0 0 2px;
    }

    &:last-of-type a:hover:before {
      border-radius: 0 2px 2px 0;
    }

    a:hover {
      + .ddp-ui-tooltip-info,
      .ddp-ui-tooltip {
        display: block;
      }
    }
  }

  &.ddp-disabled:after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

/*ul.ddp-list-tab-button li + li a {border-right:1px solid #e7e7ea;}*/

/**************************************************************
    확인버튼
**************************************************************/

.ddp-btn-check {
  display: inline-block;
  position: relative;
  width: 23px;
  height: 23px;
  background-color: #b9bcc2;
  cursor: pointer;

  &:before {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -6px;
    width: 11px;
    height: 8px;
    background: url(/assets/bi/images/icon_select2.png) no-repeat;
    background-position: -27px top;
    content: '';
  }

  &:hover {
    background-color: #999ea6;
  }
}

/**************************************************************
    list button
**************************************************************/

.ddp-list-buttons {
  li {
    &:first-of-type {
      border-left: none;
    }

    em.ddp-icon-check {
      display: none;
      margin-right: 7px;
      width: 8px;
      height: 6px;
      background: url(/assets/bi/images/icon_select2.png) no-repeat;
      background-position: -47px top;
      vertical-align: middle;
    }
  }

  &.ddp-type2 li {
    height: 26px;
    padding: 5px 0;
    box-sizing: border-box;

    .ddp-label-checkbox {
      span.ddp-txt-checkbox {
        color: #90969f;
      }

      input[type='checkbox'] + i.ddp-icon-checkbox {
        background-position: -48px top;
      }
    }

    &.ddp-selected {
      &:before {
        display: none;
      }

      .ddp-label-checkbox input[type='checkbox'] + i.ddp-icon-checkbox {
        background-position: -61px top;
      }
    }

    .ddp-label-checkbox input[type='checkbox']:checked + i.ddp-icon-checkbox + .ddp-txt-checkbox {
      font-weight: normal;
      color: #4c515a;
    }

    &.ddp-selected .ddp-label-checkbox input[type='checkbox']:checked + i.ddp-icon-checkbox + .ddp-txt-checkbox {
      color: #fff;
    }

    .ddp-label-radio input[type='radio']:checked + i.ddp-icon-radio {
      background-position: -60px top;
    }

    &.ddp-selected .ddp-label-radio input[type='radio']:checked + i.ddp-icon-radio {
      background-position: -48px top;
    }

    .ddp-label-radio input[type='radio']:checked + i.ddp-icon-radio + .ddp-txt-radio {
      font-weight: normal;
      color: #4c515a;
    }

    &.ddp-selected .ddp-label-radio input[type='radio']:checked + i.ddp-icon-radio + .ddp-txt-radio {
      color: #fff;
    }

    .ddp-label-checkbox,
    .ddp-label-radio {
      vertical-align: top;
    }

    &.ddp-selected .ddp-label-checkbox span.ddp-txt-checkbox {
      color: #fff;
    }
  }

  &.ddp-type3 li {
    background-color: #f6f6f7;

    &:hover {
      background-color: #dbdce1;
    }

    &.ddp-selected {
      background-color: #fff;
      color: #4b515b;
      box-sizing: border-box;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 2px;
        border: 1px solid #90969f;
        content: '';
      }

      &:before {
        background-position-y: -11px;
      }
    }
  }

  &.ddp-dark {
    width: 100%;
    background-color: #35393f;

    li {
      width: 50%;
      color: rgba(231, 231, 231, 0.4);
      border: 1px solid #4b515b;
      background-color: #24282e;

      &.ddp-selected {
        color: #fff;
        background-color: #4b515b;
      }
    }
  }

  &.ddp-part4 li {
    width: 25%;
    min-width: auto;
  }

  &.ddp-part5 {
    width: 100%;

    li {
      width: 20%;
      min-width: auto;
    }
  }

  &.ddp-part2 {
    width: 100%;

    li {
      width: 50%;
      min-width: auto;
    }
  }
}

/**************************************************************
    tab button
**************************************************************/

.ddp-list-tab-button.ddp-type {
  border: 1px solid #ddd;
  font-size: 0;

  span {
    display: inline-block;
    padding: 0 !important;
    position: relative;
    width: 24px;
    height: 24px;
    font-size: 0;
    cursor: pointer;

    [class*='ddp-icon-tool'] {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -5px 0 0 -7px;
    }

    &.ddp-selected:before {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid #4c515a;
      content: '';
    }
  }
}

/**************************************************************
    toggle button
**************************************************************/

/* tab */

.ddp-wrap-boxtab .ddp-box-tab {
  display: inline-block;
  float: left;
  padding: 6px 0;
  margin-right: 4px;
  min-width: 120px;
  border-radius: 3px;
  border: 1px solid #4b515b;
  color: #4b515b;
  font-size: 13px;
  text-align: center;
  box-sizing: border-box;

  &.ddp-selected {
    background-color: #4b515b;
    color: #fff;
    font-weight: bold;
  }
}

/**************************************************************
    icon button
**************************************************************/

.ddp-box-btn-r {
  display: inline-block;
  position: relative;
  width: 21px;
  height: 21px;
  background-color: #f3f3f4;
  border-radius: 2px;

  &:hover {
    background-color: #e7e7ea;
  }

  .ddp-icon-play,
  .ddp-icon-pause {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: url(/assets/bi/images/btn_status.png) no-repeat;
  }

  .ddp-icon-play {
    width: 8px;
    height: 12px;
    margin: -6px 0 0 -4px;
    background-position: left -50px;
  }

  .ddp-icon-pause {
    width: 9px;
    height: 11px;
    margin: -6px 0 0 -5px;
    background-position: left -63px;
  }
}

/**************************************************************
    new button
**************************************************************/

.ddp-wrap-add-button {
  // float: left;
  position: relative;
  padding: 0px 0px 0px 0px;
}
