@import "settings/custom-variables";

.ant-modal-content {
  /* display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -276px 0 0 -479px;
      width: 958px;
      height: 552px;
      text-align: left;
      background-color: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, .3); */
  display: grid;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 600px;
  z-index: 9999;
}

.ant-modal-close {
  cursor: pointer;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='ant-'],
[class*='ant-'],
[class^='ant-'] *,
[class*='ant-'] *,
[class^='ant-'] *::before,
[class*='ant-'] *::before,
[class^='ant-'] *::after,
[class*='ant-'] *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}

.ant-modal-confirm-body {
  > .anticon {
    float: left;
    margin-right: 16px;
    color: #faad14;
    font-size: 22px;
  }

  .ant-modal-confirm-title {
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }

  > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 38px;
    text-align: start;
  }

  .ant-modal-confirm-content {
    margin-top: 3px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.ant-modal-confirm-btns {
  margin-top: 24px;
  float: right;
}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-color: #d9d9d9;
  margin-left: 7px;

  &:hover {
    color: $primary;
    border-color: $primary;
  }
}

.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;

  &:hover {
    color: #ff4d4f;
    background: #fff;
    border-color: #ff4d4f;
  }
}
