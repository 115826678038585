ul.jqtree-tree {
  width: 100%;
  position: Relative;
  top: -9px;
  list-style: none outside;
  margin-left: 0;
  margin-bottom: 0;
  padding: 12px 0;
}

ul.jqtree-tree > li {
}
ul.jqtree-tree > li > ul.jqtree_common {
  margin-left: 12px;
}
ul.jqtree-tree ul.jqtree_common {
  list-style: none outside;
  margin-left: 37px;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  display: block;
}
.jqtree-element + ul.jqtree_common {
  margin-left: 12px;
}
ul.jqtree-tree li.jqtree-closed > ul.jqtree_common {
  display: none;
}
ul.jqtree-tree li.jqtree_common.ddp-drag-enable > .jqtree_common > .node-content-wrapper {
  border: 1px solid #666db7;
  background-color: rgb(102, 109, 183, 0.2);
}
ul.jqtree-tree li.jqtree_common.ddp-drag-disable > .jqtree_common > .node-content-wrapper {
  border: 1px solid #d7d7df;
  background-color: rgb(186, 186, 194, 0.2);
}
ul.jqtree-tree li.jqtree_common.ddp-drag-disable > .jqtree_common > .node-content-wrapper span {
  color: #d7d7df;
}
ul.jqtree-tree li.jqtree_common > div.jqtree_common {
  clear: both;
  list-style-type: none;
  padding: 9px 0;
  width: 100%;
  height: 50px;
  padding-left: 5px;
  table-layout: fixed;
  cursor: pointer;
  box-sizing: border-box;
}
ul.jqtree-tree li.jqtree_common div.jqtree_common:hover {
  background-color: #f6f6f7;
}

ul.jqtree-tree li.jqtree_common ul.jqtree_common[role='group'] em.ddp-node-depth {
  position: relative;
  float: left;
  width: 21px;
}
ul.jqtree-tree li.jqtree_common ul.jqtree_common[role='group'] em.ddp-node-depth:before {
  position: relative;
  top: -9px;
  display: inline-block;
  width: 13px;
  height: 23px;
  background: url(/assets/bi/images/icon_depth.png) no-repeat;
  content: '';
}
ul.jqtree-tree li.jqtree_common .ddp-wrap-image {
  display: table-cell;
  position: relative;
  width: 82px;
  vertical-align: middle;
}
ul.jqtree-tree li.jqtree_common .ddp-wrap-image .ddp-ui-image {
  display: inline-block;
  position: relative;
  width: 62px;
  height: 40px;
  border: 1px solid #ddd;
}
ul.jqtree-tree li.jqtree_common .ddp-wrap-image .ddp-ui-image:hover:before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(86, 93, 101, 0.1);
}
ul.jqtree-tree li.jqtree_common .ddp-wrap-image .ddp-box-hover {
  display: none;
  position: absolute;
  top: 46px;
  left: 0;
  width: 258px;
  height: 168px;
  border: 1px solid #90969f;
  z-index: 1;
}
ul.jqtree-tree li.jqtree_common .ddp-wrap-image .ddp-box-hover img {
  display: block;
  width: 100%;
  height: 100%;
}
ul.jqtree-tree li.jqtree_common .ddp-wrap-image:hover .ddp-box-hover {
  display: block;
}
ul.jqtree-tree li.jqtree_common .ddp-ui-image img {
  display: block;
  width: 100%;
  height: 100%;
}
ul.jqtree-tree li.jqtree_common .ddp-data-name {
  display: table-cell;
  width: 100%;
  color: #4b515b;
  font-size: 13px;
  vertical-align: middle;
  box-sizing: border-box;
}
ul.jqtree-tree li.jqtree_common .ddp-data-name em[class*='ddp-icon-eyes'] {
  display: inline-block;
  float: left;
  position: relative;
  top: 3px;
  margin-right: 9px;
}
ul.jqtree-tree li.jqtree_common .ddp-data-name em.ddp-icon-eyes2 {
  background-position: -17px 0;
}
ul.jqtree-tree li.jqtree_common .ddp-data-name span.ddp-txt-name {
  display: block;
  overflow: hidden;
}
ul.jqtree-tree li.jqtree_common .ddp-data-name .ddp-chart-img {
  display: inline-block;
  float: left;
}
ul.jqtree-tree .jqtree-toggler {
  border-bottom: none;
  color: #333;
  text-decoration: none;
  vertical-align: middle;
}
ul.jqtree-tree .jqtree-toggler:hover {
  color: #000;
  text-decoration: none;
}
ul.jqtree-tree .jqtree-toggler.jqtree-closed {
  background-position: 0 0;
}
ul.jqtree-tree .jqtree-toggler.jqtree-toggler-left {
  margin-right: 0.5em;
}
ul.jqtree-tree .jqtree-toggler.jqtree-toggler-right {
  margin-left: 0.5em;
}
ul.jqtree-tree .jqtree-element {
  cursor: pointer;
  position: relative;
}

ul.jqtree-tree li.jqtree-folder.jqtree-closed {
  margin-bottom: 1px;
}
ul.jqtree-tree li.jqtree-ghost {
  position: relative;
  z-index: 10;
  margin-right: 10px;
}
ul.jqtree-tree li.jqtree-ghost span {
  display: block;
}
ul.jqtree-tree li.jqtree-ghost span.jqtree-circle {
  border: solid 2px #666db7;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  height: 8px;
  width: 8px;
  position: absolute;
  top: -4px;
  left: -6px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul.jqtree-tree li.jqtree-ghost span.jqtree-line {
  background-color: #666db7;
  height: 2px;
  padding: 0;
  position: absolute;
  top: -1px;
  left: 2px;
  width: 100%;
}
ul.jqtree-tree li.jqtree-ghost.jqtree-inside {
  margin-left: 48px;
}
ul.jqtree-tree span.jqtree-border {
}
ul.jqtree-tree .jqtree-moving > .jqtree-element .jqtree-title {
  outline: dashed 1px #d7d7df;
}
ul.jqtree-tree.jqtree-rtl {
  direction: rtl;
}
ul.jqtree-tree.jqtree-rtl ul.jqtree_common {
  margin-left: 0;
  margin-right: 12px;
}
ul.jqtree-tree.jqtree-rtl .jqtree-toggler {
  margin-left: 0.5em;
  margin-right: 0;
}
ul.jqtree-tree.jqtree-rtl .jqtree-title {
  margin-left: 0;
  margin-right: 1.5em;
}
ul.jqtree-tree.jqtree-rtl .jqtree-title.jqtree-title-folder {
  margin-right: 0;
}
ul.jqtree-tree.jqtree-rtl li.jqtree-ghost {
  margin-right: 0;
  margin-left: 10px;
}
ul.jqtree-tree.jqtree-rtl li.jqtree-ghost span.jqtree-circle {
  right: -6px;
}
ul.jqtree-tree.jqtree-rtl li.jqtree-ghost span.jqtree-line {
  right: 2px;
}
ul.jqtree-tree.jqtree-rtl li.jqtree-ghost.jqtree-inside {
  margin-left: 0;
  margin-right: 48px;
}
ul.jqtree-tree.jqtree-rtl span.jqtree-border {
  right: -2px;
}

span.jqtree-dragging {
  color: #fff;
  background: #000;
  opacity: 0.6;
  cursor: pointer;
  padding: 2px 8px;
}

ul.jqtree-tree li.jqtree_common .node-content-wrapper.ddp-tree-drag-start {
  border: 1px solid #d7d7df;
  background-color: rgb(186, 186, 194, 0.2);
}
ul.jqtree-tree li.jqtree_common .node-content-wrapper.ddp-tree-drag-start span {
  color: #d7d7df;
}
