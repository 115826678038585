.p-toolbar {
  background: $panelHeaderBg;
  border: $panelHeaderBorder;
  padding: $panelHeaderPadding;
  border-radius: $borderRadius;

  .p-toolbar-separator {
    margin: 0 $inlineSpacing;
  }
}
