@mixin border-radius-right($val) {
  border-top-right-radius: $val;
  border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
  border-top-left-radius: $val;
  border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
  border-top-left-radius: $val;
  border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
  border-bottom-left-radius: $val;
  border-bottom-right-radius: $val;
}

@mixin flex() {
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-wrap($value) {
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin flex-direction-row() {
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-direction-column() {
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-justify-center() {
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin flex-justify-between() {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin flex-align-center() {
  -ms-flex-align: center;
  align-items: center;
}

@mixin flex-align-start() {
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin flex-align-end() {
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin flex-justify-start() {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flex-justify-end() {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin focused-input() {
  border-color: $inputFocusBorderColor;
  outline: 0 none;
  box-shadow: $inputFocusShadow;
}

@mixin focused() {
  outline: $focusOutline;
  outline-offset: $focusOutlineOffset;
  box-shadow: $focusShadow;
}

@mixin focused-inset() {
  outline: $focusOutline;
  outline-offset: $focusOutlineOffset;
  box-shadow: inset $focusShadow;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
    opacity: 1;
  }
  ::-moz-placeholder {
    @content;
    opacity: 1;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin gradient($deg, $color1, $color2) {
  background: -moz-linear-gradient($deg, $color1 0%, $color2 100%); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(0%, $color1),
    color-stop(100%, $color2)
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient($deg, $color1 0%, $color2 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient($deg, $color1 0%, $color2 100%); /* opera 11.10+ */
  background: -ms-linear-gradient($deg, $color1 0%, $color2 100%); /* ie10+ */
  background: linear-gradient($deg, $color1 0%, $color2 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 ); /* ie6-9 */
}

@mixin background-gradient-left2right($start-color, $end-color) {
  background: $start-color;
  background-image: -webkit-gradient(linear, left top, right top, from($start-color), to($end-color));
  background-image: -webkit-linear-gradient(left, $start-color, $end-color);
  background-image: -moz-linear-gradient(left, $start-color, $end-color);
  background-image: -ms-linear-gradient(left, $start-color, $end-color);
  background-image: -o-linear-gradient(left, $start-color, $end-color);
  background-image: linear-gradient(left, $start-color, $end-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', gradientType='1');
}

@mixin background-gradient-right2left($start-color, $end-color) {
  background: $start-color;
  background-image: -webkit-gradient(linear, right top, left top, from($start-color), to($end-color));
  background-image: -webkit-linear-gradient(right, $start-color, $end-color);
  background-image: -moz-linear-gradient(right, $start-color, $end-color);
  background-image: -ms-linear-gradient(right, $start-color, $end-color);
  background-image: -o-linear-gradient(right, $start-color, $end-color);
  background-image: linear-gradient(right, $start-color, $end-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', gradientType='1');
}

@mixin background-gradient-top2bottom($start-color, $end-color) {
  background: $start-color;
  background-image: -webkit-gradient(linear, left top, left bottom, from($start-color), to($end-color));
  background-image: -webkit-linear-gradient(top, $start-color, $end-color);
  background-image: -moz-linear-gradient(top, $start-color, $end-color);
  background-image: -ms-linear-gradient(top, $start-color, $end-color);
  background-image: -o-linear-gradient(top, $start-color, $end-color);
  background-image: linear-gradient(to bottom, $start-color, $end-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}');
}

@mixin background-gradient-bottom2top($start-color, $end-color) {
  background: $end-color;
  background-image: -webkit-gradient(linear, left bottom, left top, from($start-color), to($end-color));
  background-image: -webkit-linear-gradient(bottom, $start-color, $end-color);
  background-image: -moz-linear-gradient(bottom, $start-color, $end-color);
  background-image: -ms-linear-gradient(bottom, $start-color, $end-color);
  background-image: -o-linear-gradient(bottom, $start-color, $end-color);
  background-image: linear-gradient(to top, $start-color, $end-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}');
}

@mixin flex-center() {
  @include flex();
  @include flex-align-center();
  @include flex-justify-center();
}

@mixin nested-submenu-indents($val, $index, $length) {
  ul {
    li {
      a {
        padding-left: $val * $index * 1px;
      }

      @if $index < $length {
        @include nested-submenu-indents($val, $index + 1, $length);
      }
    }
  }
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin scale($deg) {
  -webkit-transform: scale($deg);
  -moz-transform: scale($deg);
  -o-transform: scale($deg);
  -ms-transform: scale($deg);
  transform: scale($deg);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin background-gradient-top2bottom($start-color, $end-color) {
  background-color: $start-color;
  background-image: -webkit-gradient(linear, left top, left bottom, from($start-color), to($end-color));
  background-image: -webkit-linear-gradient(top, $start-color, $end-color);
  background-image: -moz-linear-gradient(top, $start-color, $end-color);
  background-image: -ms-linear-gradient(top, $start-color, $end-color);
  background-image: -o-linear-gradient(top, $start-color, $end-color);
  background-image: linear-gradient(to bottom, $start-color, $end-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}');
}

@mixin background-gradient-left2right($start-color, $end-color) {
  background-color: $start-color;
  background-image: -webkit-gradient(linear, left top, right top, from($start-color), to($end-color));
  background-image: -webkit-linear-gradient(left, $start-color, $end-color);
  background-image: -moz-linear-gradient(left, $start-color, $end-color);
  background-image: -ms-linear-gradient(left, $start-color, $end-color);
  background-image: -o-linear-gradient(left, $start-color, $end-color);
  background-image: linear-gradient(left, $start-color, $end-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', gradientType='1');
}

@mixin shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin border-radius($val) {
  -moz-border-radius: $val;
  -webkit-border-radius: $val;
  border-radius: $val;
}

@mixin border-radius-top($val) {
  -moz-border-radius-topleft: $val;
  -webkit-border-top-left-radius: $val;
  border-top-left-radius: $val;
  -moz-border-radius-topright: $val;
  -webkit-border-top-right-radius: $val;
  border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
  -moz-border-radius-bottomleft: $val;
  -webkit-border-bottom-left-radius: $val;
  border-bottom-left-radius: $val;
  -moz-border-radius-bottomright: $val;
  -webkit-border-bottom-right-radius: $val;
  border-bottom-right-radius: $val;
}

@mixin border-radius-right($val) {
  -moz-border-radius-topright: $val;
  -webkit-border-top-right-radius: $val;
  border-top-right-radius: $val;
  -moz-border-radius-bottomright: $val;
  -webkit-border-bottom-right-radius: $val;
  border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
  -moz-border-radius-topleft: $val;
  -webkit-border-top-left-radius: $val;
  border-top-left-radius: $val;
  -moz-border-radius-bottomleft: $val;
  -webkit-border-bottom-left-radius: $val;
  border-bottom-left-radius: $val;
}

@mixin input-placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}
