@charset "utf-8";

/***************************************************************************
	page
*************************************************************************/

.ddp-ui-page {
  a {
    &.ddp-btn-prev,
    &.ddp-btn-next {
      display: inline-block;
      float: left;
      width: 21px;
      height: 21px;
      background: url(/assets/bi/images/btn_page2.png) no-repeat;
      background-color: #fff;
    }

    &.ddp-btn-prev:hover,
    &.ddp-btn-next:hover {
      background-position-y: -22px;
    }

    &.ddp-btn-prev.ddp-disabled {
      opacity: 0.3;
      cursor: no-drop;
      background-position-y: 0;
    }

    &.ddp-btn-next {
      &.ddp-disabled {
        opacity: 0.3;
        cursor: no-drop;
        background-position-y: 0;
      }

      background-position: -21px 0;
    }
  }

  span.ddp-data-page {
    display: inline-block;
    position: relative;
    top: 3px;
    float: left;
    padding: 0 6px;
    color: #444;
    font-size: 11px;
    vertical-align: top !important;

    input.ddp-input-data {
      display: inline-block;
      width: 32px;
      height: 21px;
      margin-right: 5px;
      padding: 0 8px;
      text-align: right;
      border: 1px solid #ddd;
      box-sizing: border-box;
    }

    em {
      display: inline-block;
      padding: 0 3px;
    }
  }
}

/**************************************************************
	pageing
**************************************************************/

.ddp-ui-pageing {
  padding: 10px 25px;
  overflow: hidden;

  .ddp-btn-prev,
  .ddp-btn-next {
    font-size: 12px;
    color: #90969f;
  }

  .ddp-btn-prev:hover,
  .ddp-btn-next:hover {
    color: #d0d1d8;
  }

  .ddp-btn-prev {
    float: left;
  }

  .ddp-btn-next {
    float: right;
  }

  .ddp-btn-prev.ddp-disabled,
  .ddp-btn-next.ddp-disabled,
  .ddp-btn-prev.ddp-disabled:hover,
  .ddp-btn-next.ddp-disabled:hover {
    color: #90969f;
    opacity: 0.3;
    cursor: no-drop;
  }
}

/**************************************************************
	box page
**************************************************************/

.ddp-box-page {
  padding: 0 20px 0 18px;
  margin-top: 10px;
  border: 1px solid #d5d6dc;
  background-color: #fff;
  box-sizing: border-box;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }

  .ddp-page-num {
    float: left;
    padding: 7px 0;

    a {
      display: inline-block;
      position: relative;
      float: left;
      width: 28px;
      height: 28px;
      margin: 0 2px;
      padding: 4px 0;
      text-align: center;
      color: #4b515b;
      font-size: 14px;
      box-sizing: border-box;

      &.ddp-btn-prev,
      &.ddp-btn-next {
        display: inline-block;
        position: relative;
        top: 3px;
        width: 21px;
        height: 21px;
        border: 1px solid #e7e8ec;
        border-radius: 2px;
        font-size: 0;
      }

      &.ddp-btn-prev {
        margin-right: 12px;
      }

      &.ddp-btn-next {
        margin-left: 12px;
        transform: rotate(180deg);
      }

      &.ddp-btn-prev:before,
      &.ddp-btn-next:before {
        display: Inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -4px 0 0 -2px;
        width: 4px;
        height: 7px;
        background: url(/assets/bi/images/btn_page2.png) no-repeat;
        background-position: -8px -29px;
        content: '';
      }

      &.ddp-btn-prev.ddp-disabled,
      &.ddp-btn-next.ddp-disabled {
        opacity: 0.3;
        cursor: no-drop;
      }

      &.ddp-page-skip:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -2px 0 0 -8px;
        width: 16px;
        height: 4px;
        background: url(/assets/bi/images/icon_page_skip.png) no-repeat;
        content: '';
      }

      &.ddp-selected {
        background-color: #f6f6f7;
        font-weight: bold;
      }
    }
  }

  .ddp-page-setting {
    float: right;
    padding: 6px 0;

    .ddp-wrap-edit .ddp-label-type {
      padding-right: 5px;

      + .ddp-ui-edit-option.ddp-inline {
        top: 0;
        width: 68px;
      }
    }
  }
}
