/* open-sans-300 - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../layout/fonts/open-sans/OpenSans-Light.eot'); /* IE9 Compat Modes */
  src: local(''), url('../layout/fonts/open-sans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../layout/fonts/open-sans/OpenSans-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../layout/fonts/open-sans/OpenSans-Light.woff') format('woff'),
    /* Modern Browsers */ url('../layout/fonts/open-sans/OpenSans-Light.ttf') format('truetype');
}
/* open-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../layout/fonts/open-sans/OpenSans-Regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../layout/fonts/open-sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../layout/fonts/open-sans/OpenSans-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../layout/fonts/open-sans/OpenSans-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../layout/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
}
/* open-sans-600 - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../layout/fonts/open-sans/OpenSans-Bold.eot'); /* IE9 Compat Modes */
  src: local(''), url('../layout/fonts/open-sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../layout/fonts/open-sans/OpenSans-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../layout/fonts/open-sans/OpenSans-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../layout/fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
}
/* open-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../layout/fonts/open-sans/OpenSans-ExtraBold.eot'); /* IE9 Compat Modes */
  src: local(''), url('../layout/fonts/open-sans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../layout/fonts/open-sans/OpenSans-ExtraBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../layout/fonts/open-sans/OpenSans-ExtraBold.woff') format('woff'),
    /* Modern Browsers */ url('../layout/fonts/open-sans/OpenSans-ExtraBold.ttf') format('truetype');
}
