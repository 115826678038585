@charset "utf-8";

/**************************************************************
	툴팁
**************************************************************/

input {
  &.ddp-input-type {
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
      color: #d7d7dc;
    }

    &:disabled {
      color: rgba(75, 81, 91, 0.3);
    }
  }

  &.ddp-input-typebasic {
    display: block;
    width: 100%;
    padding: 6px 10px 6px 10px;
    border-radius: 2px;
    border: 1px solid #d0d1d9;
    box-sizing: border-box;
    font-size: 13px;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
      color: #a4aab0;
    }

    &:focus {
      border: 1px solid #b7b9c2;
    }

    &:read-only:focus {
      border: 1px solid #d0d1d9;
    }

    &.ddp-full {
      width: 100%;
    }

    &:disabled,
    &.ddp-disabled {
      background-color: rgba(240, 240, 240, 0.3);
      cursor: no-drop;
      opacity: 0.5;
    }

    &.ddp-inline {
      display: inline-block;
      width: inherit;
      position: relative;
      top: -3px;
    }

    &.ddp-error {
      color: #eb5f58;
    }

    &.ddp-font-size {
      padding-top: 10px;
      font-size: 12px;
    }

    &.ddp-timeout {
      width: 289px;
    }

    &.ddp-readonly {
      padding-left: 0;
      border: 1px solid transparent;
      background: none;

      &:focus {
        padding-left: 0;
        border: 1px solid transparent;
        background: none;
      }
    }

    &.ddp-focus:focus {
      color: #4b515b;
    }
  }

  &.ddp-input-type {
    display: block;
    width: 100%;
    padding-bottom: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #e7e7ea;
  }
}

.ddp-wrap-chart .ddp-wrap-chart-lnb input.ddp-input-typebasic {
  &.ddp-disabled,
  &[disabled='disabled'] {
    background-color: #9a9c9f;
    border: 1px solid #9a9c9f;
    opacity: 1;
  }
}

.ddp-ng-ellipsis {
  span.ddp-data-ellipsis {
    display: block;
    border: 1px solid #d0d1d9;
    width: 100%;
    padding: 6px 10px 6px 10px;
    border-radius: 2px;
    font-size: 13px;
    background-color: #fff;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  input {
    display: none;
  }

  &.ddp-selected {
    span.ddp-data-ellipsis {
      display: none;
    }

    input,
    .ddp-ui-tooltip-info {
      display: block;
    }
  }
}

/**************************************************************
    적용 input
**************************************************************/

.ddp-input-apply {
  position: relative;

  &.ddp-disabled {
    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      z-index: 3;
    }

    opacity: 0.5;
    background-color: rgba(240, 240, 240, 0.3);
    cursor: no-drop;

    input.ddp-input-txt {
      background: rgba(240, 240, 240, 0.3);
    }

    &:hover input.ddp-input-txt {
      border: 1px solid #d0d1d8;
    }
  }

  &.ddp-disabledinput.ddp-input-txt:focus {
    border: 1px solid #d0d1d8;
  }

  &.ddp-disabled .ddp-icon-apply {
    display: block;
  }

  input.ddp-input-txt {
    display: block;
    width: 100%;
    padding: 6px 10px 6px 10px;
    color: #4b515b;
    font-size: 13px;
    text-align: left;
    border: 1px solid #d0d1d8;
    border-radius: 2px;
    background-color: #fff;
    box-sizing: border-box;

    &:focus {
      padding-right: 28px;
    }
  }

  &.ddp-edit input.ddp-input-txt {
    padding-right: 28px;
  }

  .ddp-icon-apply {
    display: none;
    position: absolute;
    top: 50%;
    right: 3px;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-color: #d0d1d8;

    &:before {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -4px 0 0 -6px;
      width: 11px;
      height: 8px;
      background: url(/assets/bi/images/icon_select2.png) no-repeat;
      background-position: -27px top;
      content: '';
    }
  }

  &:hover input.ddp-input-txt {
    border: 1px solid #b7b9c2;
  }

  input.ddp-input-txt:focus {
    border: 1px solid #b7b9c2;

    + .ddp-icon-apply {
      display: block;
    }
  }

  &.ddp-edit {
    input.ddp-input-txt {
      border: 1px solid #b7b9c2;
    }

    .ddp-icon-apply {
      display: block;
      background-color: #9ca2cc;
    }
  }

  &.ddp-loading input.ddp-input-txt {
    border: 1px solid #b7b9c2;
  }

  .ddp-icon-apply-loading {
    display: none;
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -6px;
    width: 12px;
    height: 12px;
    background: url(/assets/bi/images/icon_tabloading.png) no-repeat;
    vertical-align: middle;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &.ddp-loading {
    .ddp-icon-apply-loading {
      display: block;
    }

    .ddp-input-form,
    &:hover .ddp-icon-apply {
      display: none;
    }

    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      content: '';
    }
  }
}

.ddp-input-apply2 {
  border: 1px solid #d0d1d8;
  background-color: #fff;

  &:hover {
    border: 1px solid #b7b9c2;
  }

  .txt-apply {
    position: relative;
    float: right;

    .txt-data {
      float: left;
      position: Relative;
      top: 4px;
      margin: 0 5px;
      color: #bdc0c9;
      font-size: 12px;
    }

    .ddp-icon-apply {
      display: none;
      float: left;
      position: relative;
      width: 24px;
      height: 24px;
      margin: 2px 2px 0 0;
      background-color: #d0d1d8;
      cursor: pointer;

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -4px 0 0 -6px;
        width: 11px;
        height: 8px;
        background: url(/assets/bi/images/icon_select2.png) no-repeat;
        background-position: -27px top;
        content: '';
      }
    }
  }

  .ddp-wrap-input {
    display: block;
    overflow: hidden;

    input {
      display: block;
      width: 100%;
      padding: 6px 0 7px 5px;
      border: none;
      background: none;
      box-sizing: border-box;
    }
  }

  &.ddp-edit .txt-apply .ddp-icon-apply {
    display: block;
    background-color: #9ca2cc;
  }

  &.ddp-focus .txt-apply .ddp-icon-apply {
    display: block;
  }
}

/**************************************************************
        calen
    **************************************************************/

.ddp-form-calen {
  position: relative;

  input.ddp-input-typebasic {
    padding-right: 30px;
  }

  &:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -6px;
    width: 11px;
    height: 11px;
    background: url(/assets/bi/images/icon_type.png) no-repeat;
    content: '';
  }
}

/**************************************************************
    textarea
**************************************************************/

.ddp-textarea-auto {
  display: block;
  width: 100% !important;
  max-width: 100%;
  padding: 6px 10px 6px 10px;
  height: 31px;
  min-height: 31px;
  font-size: 13px;
  resize: auto;
  box-sizing: border-box;
  border: 1px solid #d0d1d8;
  box-sizing: border-box;
}
