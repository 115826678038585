.login-body {
  background: linear-gradient(180deg, #525d72 0%, #69768d 100%);
  color: #c3ccdd;
  height: 100vh;

  .login-wrapper {
    @include flex();
    @include flex-align-center();
    @include flex-justify-center();
    height: 100vh;

    .login-panel {
      width: 30%;
      height: 100%;
      text-align: center;
      padding: 40px 20px;
      @include flex();
      @include flex-align-center();
      @include flex-justify-between();
      @include flex-direction-column();

      .logo {
        img {
          height: 45px;
        }
      }

      .login-form {
        > p {
          font-weight: 600;
          margin: 0;
          color: #c3ccdd;
          margin-bottom: 32px;

          > a {
            color: #f4f7f9;
            cursor: pointer;
          }
        }

        > input {
          width: 85%;
          max-width: 310px;
          margin-bottom: 20px;
          background-color: #f6f9fe;
          color: #515c66;

          &::placeholder {
            color: #515c66;
          }
        }

        > button {
          width: 85%;
          max-width: 310px;
        }
      }

      p {
        font-weight: 600;
        margin: 0;
        color: #c3ccdd;

        > a {
          color: #f4f7f9;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .login-body {
    .login-wrapper {
      .login-panel {
        width: 100%;
      }
    }
  }
}
