@charset "utf-8";

/**************************************************************
	라디오버튼 폼
**************************************************************/

.ddp-label-radio {
  display: inline-block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;

  span {
    &.ddp-txt-radio,
    &.ddp-txt-checkbox {
      display: inline-block;
      font-size: 13px;
      color: #4b515b;
      vertical-align: bottom;
      word-break: break-all;
    }
  }

  &.type-success span.ddp-txt-radio {
    color: #10bf83;
  }

  &.type-failed span.ddp-txt-radio {
    color: #dc494f;
  }

  span {
    &.ddp-txt-radio span.ddp-data-valuenum,
    &.ddp-txt-checkbox span.ddp-data-valuenum {
      position: relative;
      top: -1px;
      margin-left: 5px;
      color: #90969f;
      font-size: 12px;
    }

    &.ddp-txt-radio {
      span.ddp-txt-det {
        color: #808dcd;
        font-style: italic;
      }

      .ddp-box-tag-value {
        display: inline-block;
        padding: 2px;
        border-radius: 2px;

        &.ddp-dimension {
          border: 1px solid #439fe5;
          color: #439fe5;
          font-size: 12px;
        }

        &.ddp-measure {
          border: 1px solid #5fd7a5;
          color: #5fd7a5;
          font-size: 12px;
        }
      }
    }
  }

  input[type='radio'] {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    opacity: 0;
  }

  i.ddp-icon-radio {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    width: 12px;
    height: 12px;
    background: url(/assets/bi/images/icon_radio.png) no-repeat;
    background-position: left -24px;
  }

  input[type='radio']:checked + i.ddp-icon-radio,
  &.ddp-radiotype input[type='radio']:checked + i.ddp-icon-radio {
    background-position: -13px -24px;
  }

  input[type='radio'] {
    &:checked + i.ddp-icon-radio + .ddp-txt-radio {
      font-weight: bold;

      .ddp-txt-det {
        font-weight: normal;
      }
    }

    &:disabled + i.ddp-icon-radio {
      background-position: -26px -24px;

      + .ddp-txt-radio {
        color: #d0d1d8;
        cursor: no-drop;
      }
    }
  }

  &.ddp-radiotype input[type='radio'] {
    &:disabled + i.ddp-icon-radio {
      color: #d0d1d8;
      cursor: no-drop;
    }

    + i.ddp-icon-radio {
      background-position: -36px top;
    }
  }

  &.ddp-inline {
    display: inline-block;
    margin-right: 25px;
  }

  &.ddp-dark {
    i {
      background-position: -12px -12px;
    }

    .ddp-txt-radio {
      color: #90969f;
      font-size: 12px;
    }

    input[type='radio'] {
      &:checked + i.ddp-icon-radio {
        background-position: left -12px;
      }

      &:disabled + i.ddp-icon-radio {
        background-position: -24px -12px;

        + .ddp-txt-radio {
          color: #d0d1d8;
          cursor: no-drop;
          opacity: 0.3;
        }
      }
    }
  }
}

.ddp-label-radio2 {
  display: inline-block;
  position: relative;

  input {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  i.ddp-icon-radio {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url(/assets/bi/images/icon_radio.png) no-repeat;
    background-position: -84px top;
  }

  input:checked + i.ddp-icon-radio {
    background-position: -72px top;
  }
}

.ddp-ui-radio {
  position: relative;
  display: inline-block;

  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  i.ddp-icon-radio {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url(/assets/bi/images/icon_radio.png) no-repeat;
  }

  input[type='radio']:checked + i.ddp-icon-radio {
    background-position: -12px top;
  }

  &.ddp-checkboxtype input[type='radio'] {
    &:checked + i.ddp-icon-radio {
      background-position: -12px top;
    }

    + i.ddp-icon-radio {
      background-position: -24px top;
    }
  }
}
