@charset "utf-8";

/**************************************************************
	툴팁
**************************************************************/

.ddp-icon-question {
  display: inline-block;
  width: 11px;
  height: 11px;
  background: url(/assets/bi/images/icon_que.png) no-repeat;
}

.ddp-ui-tooltip {
  display: inline-block;
  position: relative;
  margin-left: 2px;
  cursor: pointer;
  font-weight: normal;

  em.ddp-icon-question {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url(/assets/bi/images/icon_que.png) no-repeat;
  }
}

.ddp-ui-tooltip-info {
  display: none;
  position: absolute;
  top: -7px;
  left: 17px;
  min-width: 15px;
  padding: 5px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 3px;
  background-color: $primary;
  z-index: 1;

  em {
    &.ddp-icon-view {
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 4px;
      height: 8px;
      margin-left: -4px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
    }

    &.ddp-icon-view-top {
      display: inline-block;
      position: absolute;
      top: -6px;
      left: 50%;
      width: 4px;
      height: 8px;
      margin-left: -4px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
      transform: rotate(90deg);
    }

    &.ddp-icon-view-down {
      display: inline-block;
      position: absolute;
      bottom: -6px;
      left: 50%;
      width: 4px;
      height: 8px;
      margin-left: -4px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
      transform: rotate(-90deg);
    }

    &.ddp-icon-view-left {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: -4px;
      width: 4px;
      height: 8px;
      margin-top: -4px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
    }

    &.ddp-icon-view-right,
    &.ddp-icon-view-add {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: -4px;
      width: 4px;
      height: 8px;
      margin-top: -4px;
      background: url(/assets/bi/images/icon_tagview.png) no-repeat;
      transform: rotate(180deg);
    }
  }
}

.ddp-ui-tooltip.ddp-selected .ddp-ui-tooltip-info {
  display: block;
}

.ddp-ui-tooltip-info {
  &.ddp-down {
    position: absolute;
    top: 100%;
    left: inherit;
    right: 0;
    margin-top: 6px;
    font-weight: normal;
    z-index: 125;

    em.ddp-icon-view-top {
      left: inherit;
      right: 15px;
    }
  }

  &.ddp-top {
    position: absolute;
    bottom: 100%;
    top: inherit;
    left: 25px;

    .ddp-icon-view-top {
      top: 100%;
      transform: rotate(-90deg);
      margin-top: -2px;
    }
  }
}

.ddp-hover-tooltip {
  position: relative;

  &:hover .ddp-ui-tooltip-info {
    display: block;
    z-index: 11 !important;
  }

  &.ddp-selected:hover .ddp-ui-tooltip-info {
    display: none;
  }
}

.ddp-ui-tooltip-info {
  .ddp-tooltip-label {
    display: block;
    padding-bottom: 7px;
    font-size: 12px;
    color: #b7b9c2;
    font-weight: bold;
  }

  .ddp-dl-info {
    + .ddp-dl-info {
      padding-top: 6px;
    }

    dt {
      font-size: 12px;
      color: #f6f6f7;
      font-weight: 300;
    }

    dd {
      font-size: 12px;
      color: #f6f6f7;
    }
  }
}
