.layout-config {
  position: fixed;
  padding: 0;
  top: 0px;
  right: 0;
  width: 250px;
  z-index: 999;
  height: 100%;
  transform: translate3d(250px, 0px, 0px);
  @include transition(transform $transitionDuration);
  background-color: lighten($contentBgColor, 2%);
  box-shadow: 0 24px 64px -2px rgba(0, 0, 0, 0.02), 0 6px 16px -2px rgba(0, 0, 0, 0.06),
    0 2px 6px -2px rgba(0, 0, 0, 0.08);

  &.layout-config-active {
    transform: translate3d(0px, 0px, 0px);

    .layout-config-button {
      i {
        @include rotate(360deg);
      }
    }
  }

  .layout-config-button {
    display: block;
    position: absolute;
    width: 52px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    bottom: 23px;
    left: -70px;
    z-index: -1;
    cursor: pointer;
    transition: background-color 0.2s;
    border-radius: 4px;
    background-color: #2c84d8;
    background-color: var(--primary-dark-color, #2c84d8);
    color: #fff;
    @include transition(background-color $transitionDuration);

    i {
      font-size: 32px;
      line-height: inherit;
      cursor: pointer;
      @include rotate(0deg);
      @include transition(transform 1s);
    }

    &:hover {
      background-color: #2873d3;
      background-color: var(--primary-color, #2873d3);
      color: #ffffff;
    }
  }

  .layout-config-content {
    position: relative;
    height: 100%;
    overflow: auto;

    .layout-config-form {
      overflow: auto;
      overflow-x: hidden;
    }

    .layout-config-header {
      padding: 18px 12px;
      margin-bottom: 12px;
      color: #ffffff;
      background: var(--primary-600);

      > span {
        font-size: 12px;
        display: block;
        color: #ffffff;
        color: var(--primary-color-text, #ffffff);
      }
    }

    .layout-config-section {
      padding: 10px 12px;

      .section-name {
        font-weight: 500;
        font-size: 12px;
        display: block;
        color: $textColor;
      }

      &.colors {
        .layout-config-colors {
          padding: 0 10px;
          margin-top: 3px;

          .col-fixed {
            padding: 0.5em;
          }
        }
      }

      &.options {
        label {
          font-size: 0.875rem;
        }
      }

      a {
        @include flex-center();
        @include flex-direction-column();
        overflow: hidden;
        @include transition(transform $transitionDuration);
        position: relative;
        color: $textColor;
        text-transform: capitalize;

        &:hover {
          @include scale(1.1);
        }

        &.layout-config-option {
          .layout-config-option-text {
            margin-top: 0.2em;
          }

          .layout-config-option-color {
            width: 24px;
            height: 24px;
            @include border-radius(4px);
            position: relative;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.1);

            .layout-config-option-accentcolor {
              display: block;
              width: 18px;
              height: 28px;
              position: absolute;
              bottom: -10px;
              right: -7px;
              transform: rotate(45deg);
            }
          }

          .layout-config-option-check-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            @include flex-center();

            i {
              @include flex-center();
              font-size: 12px;
              color: $primaryColor;
              @include border-radius(50%);
              background-color: #ffffff;
              width: 16px;
              height: 16px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.blocked-scroll-config {
  overflow: hidden;
}

.layout-rtl {
  .layout-config {
    right: auto;
    left: 0;
    transform: translate3d(-250px, 0px, 0px);

    &.layout-config-active {
      transform: translate3d(0px, 0px, 0px);

      .layout-config-button {
        i {
          @include rotate(360deg);
        }
      }
    }

    .layout-config-button {
      left: auto;
      right: -51px;
    }
  }
}

@media (max-width: 992px) {
  .layout-config {
    height: 100%;
    width: 70vw;
    transform: translate3d(70vw, 0px, 0px);

    &.layout-config-active {
      transform: translate3d(0px, 0px, 0px);
    }
  }

  .layout-rtl {
    .layout-config {
      height: 100%;
      width: 70vw;
      transform: translate3d(-70vw, 0px, 0px);

      &.layout-config-active {
        transform: translate3d(0px, 0px, 0px);
      }
    }
  }
}
