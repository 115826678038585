/* Utils */
.clearfix:after {
  content: ' ';
  display: block;
  clear: both;
}

.card {
  border-radius: $borderRadius;
  background: $contentBgColor;
  padding: 12px 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  @include border-radius($borderRadius);
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  .card-header {
    @include flex();
    @include flex-align-center();
    @include flex-justify-between();
    padding-bottom: 6px;

    h6,
    h5 {
      margin-bottom: 0;
      color: $textSecondaryColor;
    }

    .header-buttons {
      @include flex();
      @include flex-align-center();
    }
  }

  &.no-gutter {
    margin-bottom: 0;
  }
}

.p-sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
