@charset "utf-8";

/**************************************************************
  top Filtering
**************************************************************/

.ddp-wrap-top-filtering {
  position: relative;
  min-height: 54px;
  background-color: #f6f9fe;
  z-index: 10;

  .ddp-filter-more {
    position: relative;
    float: left;
    margin-right: -7px;

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      top: 45px;
      left: 0;
      min-width: 134px;
    }

    .ddp-box-result.ddp-selected .ddp-wrap-popup2 {
      display: block;
      top: 28px;
    }
  }

  .ddp-btn-filter-more {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background-color: #c8cad1;

    &:hover {
      background-color: #b6b9c2;
    }

    &:before {
      display: inline-block;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      background: url(/assets/bi/images/icon_add.png) no-repeat;
      background-position: left -77px;
      content: '';
    }

    &:hover:before {
      background-position-x: -13px;
    }
  }

  .ddp-form-filtering {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-left: 20px;

    .ddp-result-filtering {
      margin-left: 20px;
      padding: 5px 0;
      cursor: pointer;
    }
  }

  .ddp-wrap-form-filtering {
    padding: 14px 0 15px 0;
    box-sizing: border-box;
  }

  .ddp-form-filtering {
    .ddp-result-filtering {
      .ddp-txt-label {
        display: inline-block;
        color: #90969f;
        font-size: 13px;
      }

      .ddp-ui-result {
        display: inline-block;
        vertical-align: top;

        + .ddp-ui-result {
          margin-left: 20px;
        }
      }

      .ddp-btn-del {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 4px;
        background: url(/assets/bi/images/btn_sclose.png) no-repeat;
        background-position: left -131px;
      }

      .ddp-box-result {
        display: inline-block;
        position: relative;
        margin-left: 4px;
        max-width: 250px;
        padding-right: 15px;
        cursor: pointer;
        box-sizing: border-box;
        vertical-align: top;

        .ddp-txt-result {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: Hidden;
        }

        &:before {
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 0;
          width: 8px;
          height: 4px;
          margin-top: -2px;
          background: url('/assets/bi/images/icon_select.png') no-repeat;
          background-position: -16px top;
          content: '';
        }
      }
    }

    .ddp-wrap-popup2 {
      display: none;
      position: absolute;
      top: 24px;
      left: 0;
      min-width: 180px;
      max-width: 250px;
      max-height: 250px;
      overflow: auto;
    }
  }

  .ddp-result-filtering.ddp-selected .ddp-wrap-popup2 {
    display: block;
    top: 31px;
  }

  .ddp-form-filtering {
    .ddp-result-filtering.ddp-selected .ddp-wrap-popup2 {
      display: block;
      left: 20px;
    }

    .ddp-wrap-popup2.ddp-date .ddp-ui-dateinfo .ddp-box-dateinfo input {
      &.ddp-input-calen {
        width: 74px;
        padding-right: 2px;
      }

      &.ddp-input-time {
        width: 36px;
        padding-left: 2px;
        padding-right: 2px;
      }
    }

    ul.ddp-list-popup {
      + ul.ddp-list-popup {
        border-top: 1px solid #e7e7ea;
      }

      li a .ddp-wrap-between .ddp-wrap-dateinfo {
        position: relative;
        margin-top: 3px;
        padding-left: 16px;

        .ddp-txt-bar {
          position: absolute;
          top: 6px;
          right: 0;
        }

        .ddp-ui-dateinfo .ddp-box-dateinfo {
          width: 105px;
        }
      }
    }

    .ddp-result-filtering .ddp-wrap-popup2 .ddp-pop-search {
      position: relative;
      padding: 0 0 0 33px;
      border-bottom: 1px solid #e7e7ea;

      input {
        display: block;
        padding: 13px 0;
        width: 100%;
        border: none;
        background: none;
        box-sizing: border-box;
      }

      &:before {
        display: Inline-block;
        position: absolute;
        top: 50%;
        left: 10px;
        margin-top: -7px;
        width: 14px;
        height: 14px;
        background: url('/assets/bi/images/icon_search.png') no-repeat;
        background-position: left -12px;
        content: '';
      }
    }
  }

  .ddp-ui-dateinfo .ddp-box-dateinfo input.ddp-full {
    width: 100%;
  }

  .ddp-filter-search {
    .ddp-form-filter-search {
      float: left;
      position: relative;
      padding-left: 25px;
      padding-right: 20px;
      margin-right: 10px;
      width: 275px;
      box-sizing: border-box;

      &.type-auto {
        width: auto;

        span.ddp-text-placeholder {
          display: inline-block;
          opacity: 0;
          line-height: 15px;
          margin-bottom: -15px;
          font-family: sans-serif;
          font-size: 13px;
        }

        input:focus + .ddp-text-placeholder {
          width: 175px;
          white-space: nowrap;

          + .ddp-btn-delete {
            display: block;
          }
        }
      }

      input {
        display: block;
        padding: 18px 0;
        width: 100%;
        font-size: 13px;
        box-sizing: border-box;
        background: none;
        border: none;
      }

      &:before {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -7px;
        width: 14px;
        height: 14px;
        background: url('/assets/bi/images/icon_search.png') no-repeat;
        background-position: -15px -12px;
        content: '';
      }
    }

    .ddp-btn-delete {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -7px;
      width: 14px;
      height: 14px;
      background: url(/assets/bi/images/btn_sclose.png) no-repeat;
      background-position: left -131px;
    }

    .ddp-type-search {
      width: 195px;
      float: left;
    }

    .ddp-btn-selection {
      float: left;
      margin: 10px 0 0 0;
    }
  }
}

/*.ddp-wrap-top-filtering .ddp-form-filtering .ddp-result-filtering.ddp-selected .ddp-txt-label {visibility:hidden;}*/

.ddp-filter-option {
  padding: 18px 0 11px 0;
  text-align: right;
  min-height: 50px;

  .ddp-btn-pop {
    padding: 8px 9px;
  }

  .ddp-data-total {
    position: relative;
    top: 8px;
    float: left;

    &.type-right {
      float: initial;
      top: 0;
      vertical-align: middle;
      color: #90969f;
    }
  }

  .ddp-form-buttons {
    display: inline-block;
    margin-left: 19px;

    [class*='ddp-btn-'] + [class*='ddp-btn-'] {
      margin-left: 7px;
    }
  }
}

.ddp-wrap-top-filtering.type-form {
  margin: 0;
  padding: 0 24px;
  min-height: 42px;
  background-color: #515979;

  .ddp-form-filtering .ddp-result-filtering {
    .ddp-txt-label {
      color: #fff;
      opacity: 0.4;
    }

    .ddp-box-result {
      .ddp-txt-result {
        color: #fff;
      }

      &:before {
        background-position: -32px top;
      }
    }
  }

  .ddp-wrap-form-filtering {
    padding: 8px 0;
    margin-bottom: 15px;
  }
}
