.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #fdfeff;
  padding: 12px 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 2rem;
  gap: 2rem;
}

.prime-table-custom__paginator {
  display: none;
}

.p-column-filter-buttonbar {
  gap: 1rem;
}

.p-datatable-sm {
  table {
    table-layout: fixed;
  }

  th {
    svg {
      vertical-align: middle;
    }
  }

  tr {
    .actions {
      opacity: 0;

      .p-button-text {
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0);

        &:hover {
          background-color: #e0e0e0;
        }
      }
    }

    &:hover {
      .actions {
        opacity: 1;
      }
    }
  }
}
