.alert-dismissable .close,
.alert-dismissible .close {
  color: inherit;
  text-shadow: none;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.alert-inverse {
  background-color: #333;
  border-color: transparent;
  color: #fff;

  .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: #fff;
    text-shadow: none;
  }

  .alert-link {
    color: #e6e6e6;
  }
}

.growl-animated {
  &.alert-inverse {
    box-shadow: 0 0 5px rgba(51, 51, 51, 0.5);
  }

  &.alert-info {
    box-shadow: 0 0 5px transparentize(theme-color('primary'), 0.5);
  }

  &.alert-success {
    box-shadow: 0 0 5px transparentize(theme-color('success'), 0.5);
  }

  &.alert-warning {
    box-shadow: 0 0 5px transparentize(theme-color('warning'), 0.5);
  }

  &.alert-danger {
    box-shadow: 0 0 5px transparentize(theme-color('danger'), 0.5);
  }
}

[data-notify='progressbar'] {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
}
